import { Switch } from '@headlessui/react'
import { useClassNames } from "../../hooks/useClassNames"

export const Toggle = ({ onChange, enabled, label, name, onText, offText }) => {
  const classNames = useClassNames()

  const handleOnChange = (e, type) => {
    const payload = {
      target: { value: e, name }
    }
    onChange(payload, type)
  }

  return (
    <div className="px-4 sm:px-6 mt-2 flex items-center justify-between">
      <span className="flex-grow flex flex-col" id="availability-label">
        <span className="text-sm font-medium text-gray-500">{label}</span>
      </span>
      <Switch.Group as="div" className="flex items-center">
        <Switch.Label as="span" className="mr-3">
          {
            enabled && onText &&
            <span className="text-sm font-medium text-gray-900">
              {onText}
            </span>
          }
          {
            !enabled && offText &&
            <span className="text-sm font-medium text-gray-900">
              {offText}
            </span>
          }
        </Switch.Label>
        <Switch
          checked={enabled}
          onChange={handleOnChange}
          name={name}
          className={classNames.classNames(
            enabled ? 'bg-indigo-600' : 'bg-gray-200',
            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          )}
        >
          <span
            aria-hidden="true"
            className={classNames.classNames(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
            )}
          />
        </Switch>
      </Switch.Group>
    </div>
  );
}