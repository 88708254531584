export const useFileSaver = () => {
    
    return {
        saveAs: (fileName, data,dataType) => {
            const element = document.createElement("a");
            const file = new Blob([data], { type: dataType });
            element.href = URL.createObjectURL(file);
            element.download = fileName;
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        }
    }
}