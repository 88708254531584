import MarginPaddingPicker from '../MarginPaddingPicker'

export const MarginPadding = ({ onChange, label, name, value, type }) => {
  return (
    <div className="px-4 sm:px-6 mt-2 flex items-center justify-between">

      <span className="flex-grow flex flex-col" id="availability-label">
        <span className="text-sm font-medium text-gray-500">{label}</span>
      </span>

      <MarginPaddingPicker name={name} value={value} onChange={onChange} type={type} />
    </div>
  );
}