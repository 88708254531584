import { post, get, put, destroy } from './api'

export const createWebhook = async (dialogDefinitionId, url, name, sendData, status, type, source, authorizationSchemaOverride) => {
  return await post('webhooks', { dialogDefinitionId, url, name, sendData, status, type, source, authorizationSchemaOverride });
}

export const getWebhook = async (id) => {
  return await get(`webhooks/${id}`);
}

export const updateWebhook = async (id, dialogDefinitionId, url, name, sendData, status, type, source, authorizationSchemaOverride) => {
  return await put(`webhooks/${id}`, { dialogDefinitionId, url, name, sendData, status, type, source, authorizationSchemaOverride });
}

export const getWebhooksByDialogDefinitionId = async (dialogDefinitionId) => {
  return await get(`dialogDefinitions/${dialogDefinitionId}/webhooks`);
}

export const triggerWebhook = async (webhookId, dialogValuesId) => {
  return await post(`webhooks/${webhookId}/dialogValues/${dialogValuesId}/trigger`);
}

export const deleteWebhook = async (webhookId) => {
  return await destroy(`webhooks/${webhookId}`)
}

export const activateWebhook = async (webhookId) => {
  return await put(`webhooks/${webhookId}/activate`)
}

export const deactivateWebhook = async (webhookId) => {
  return await put(`webhooks/${webhookId}/deactivate`)
}