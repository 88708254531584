export const setProperty = (o, path, value, type) => {
    let valueFromType = value;

    if(type) {
        switch (type) {
            case 'number':
            valueFromType = +value;
            break;
            case 'bool':
            valueFromType = (value === 'true');
            break;
            default:
            valueFromType = value;
            break;
        }
    }

    const props = path.split('.');
    const prop = props.shift()
    if (props.length === 0) {
        o[prop] = valueFromType
    } else {
        o[prop] = o[prop] ?? {}
        setProperty(o[prop], props.join('.'), valueFromType)
    }
}