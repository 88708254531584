import React, { useState, useEffect } from 'react'
import * as _ from 'lodash'
import axios from 'axios'
import { ShowHideContext } from '../../contexts'
import { RadioGroup } from '@headlessui/react'

import './styles.scss'
import { useElementDesign } from '../useElementDesign'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function FlowSelector({data, ...props}) {
  const [selected, setSelected] = useState(data.flowOptions[0])
  const showHideContext = React.useContext(ShowHideContext);

  const updateValue = (value) => {
    setSelected(value)

    const option = data.flowOptions.find(element => element.value == value);

    showHideContext?.setFlowSelectorStartAndStop(option.startContainer, option.stopContainer)
  }

  const elementDesign = useElementDesign(data.id);

  return (
    <RadioGroup
      value={selected}
      onChange={updateValue}
      className={elementDesign.elementsClasses()}
      onClick={elementDesign.onClick}
    >
      <div className="space-y-4">
        {data.flowOptions.map((plan) => (
          <RadioGroup.Option
            key={plan.name}
            value={plan.value}
            className={({ active }) =>
              classNames(
                'relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none'
              )
            }
          >
            {({ checked }) => (
              <>
                <div className="flex items-center">
                  <div className="text-sm">
                    <RadioGroup.Label as="p" className="p-2 text-lg font-medium text-gray-900">
                      {plan.name}
                    </RadioGroup.Label>
                  </div>
                </div>
                <div
                  className={classNames(
                    checked ? 'border-green' : 'border-transparent',
                    'absolute -inset-px rounded-lg border-2 pointer-events-none'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}

{/* <RadioGroup value={selected} onChange={updateValue}>
    <RadioGroup.Label className="sr-only">Privacy setting</RadioGroup.Label>
    <div className="bg-white rounded-md -space-y-px">
      {data.options.map((setting, settingIdx) => (
        <RadioGroup.Option
          key={setting.name}
          value={setting.nextContainer}
          className={({ checked }) =>
            classNames(
              settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
              settingIdx === data.options.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
              checked ? ' bg-green border-green z-10' : 'border-gray-200',
              'relative border p-4 flex cursor-pointer focus:outline-none'
            )
          }
        >
          {({ active, checked }) => (
            <>
              
              <div className="ml-3 flex flex-col">
                <RadioGroup.Label
                  as="span"
                  className={classNames('text-gray-900', 'block text-sm font-medium')}
                >
                  {setting.name}
                </RadioGroup.Label>
                <RadioGroup.Description
                  as="span"
                  className={classNames('text-gray-500', 'block text-sm')}
                >
                  {setting.description}
                </RadioGroup.Description>
              </div>
            </>
          )}
        </RadioGroup.Option>
      ))}
    </div>
  </RadioGroup> */}

{/* <fieldset>
        <div className="bg-white rounded-md -space-y-px">

          {
            data.options.map((option, index) => {
              return (
              <label
                className={`${index === 0 ? 'rounded-tl-md rounded-tr-md' : ''} ${index === data.options.length - 1 ? 'rounded-bl-md rounded-br-md' : ''} border-gray-200 relative border p-4 flex cursor-pointer`}
                >
                <input type="radio" name="privacy_setting" value="Public access"
                  className="h-4 w-4 mt-0.5 cursor-pointer border-gray-300 " aria-labelledby="privacy-setting-0-label" aria-describedby="privacy-setting-0-description"
                />
                <div className="ml-3 flex flex-col">
                  <span id="privacy-setting-0-label" className="text-gray-900 block text-sm font-medium">
                    {option.name}
                  </span>
                  <span id="privacy-setting-0-description" className="text-gray-500 block text-sm">
                    {option.description}
                  </span>
                </div>
              </label>
            )})
          }

        </div>
      </fieldset> */}