import { useState } from "react";

/**
 * @param {*} Border 
 */
export const Border = ({ onChange, label, name }) => {
  const [style, setStyle] = useState('all');
  const [width, setWidth] = useState(0);

  const handleStyleChange = (e) => {
    const { value } = e.target;

    setStyle(value);
    handleOnChange(e)
  }

  const handleWidthChange = (e) => {
    const { value } = e.target;

    setWidth(value);
    handleOnChange(e)
  }

  const handleOnChange = (e, type)  => {
    let borderWidth = 0;

    switch (style) {
      case 'all':
        borderWidth = `${width}px`
        break;
      case 'top':
        borderWidth = `${width}px 0 0 0`
        break;
      case 'bottom':
        borderWidth = `0 0 ${width}px 0`
        break;
      case 'left':
          borderWidth = `0 ${width}px 0 0`
          break;
      case 'right':
          borderWidth = `0 0 0 ${width}px`
          break;
      default:
        borderWidth = '0';
        break;
    }
    
    e.target = {
      name: name,
      value: borderWidth
    };

    onChange(e, type)
  }

  return (
    <div className="px-4 sm:px-6 mt-2 flex items-center justify-between">
        <span className="flex-grow flex flex-col" id="availability-label">
          <span className="text-sm font-medium text-gray-900">{label}</span>
        </span>

        <div className="mt-1 relative rounded-md shadow-sm">
          <input
            type="text"
            name="width"
            onChange={handleWidthChange}
            className="focus:ring-gray-400 focus:border-gray-400 block w-48 pr-12 sm:text-sm border-gray-300 rounded-md"
          />
          <div className="absolute inset-y-0 right-0 flex items-center">
            <label htmlFor="currency" className="sr-only">
              Border
            </label>
            <select
              id="style"
              onChange={handleStyleChange}
              className="focus:ring-gray-400 focus:border-gray-400 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
            >
              <option value={'all'}>All</option>
              <option value={'top'}>Top</option>
              <option value={'bottom'}>Bottom</option>
              <option value={'left'}>Left</option>
              <option value={'right'}>Right</option>
            </select>
          </div>
        </div>
      </div>
  );
}