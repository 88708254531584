import { destroy, get, post, put } from './api'


export const getAllDialogObjects = async () => {
    return await get(`dialogObjects`);
}

export const getDialogObject = async (id) => {
    return await get(`dialogObjects/${id}`)
}

export const getAllActiveDialogObjects = async () => {
    return await get(`dialogObjects/active`);
}

export const createDialogObject = async (dialogObject, name) => {
    return await post(`dialogObjects`, {
        container: dialogObject,
        name,
    });
}

export const importDialogObject = async (fileContent) => {
    return await post(`dialogObjects`, {
        container: fileContent.container,
        name: fileContent.name,
    });
}

export const deleteDialogObject = async (id) => {
    return await destroy(`dialogObjects/${id}`)
}

export const activateDialogObject = async (id) => {
    return await put(`dialogObjects/${id}/activate`)
}

export const deactivateDialogObject = async (id) => {
    return await put(`dialogObjects/${id}/deactivate`)
}

export const getDialogObjectSchema = async () => {
    return await get(`dialogObjects/schema`)
}

export const updateDialogObjectName = async (id, name) => {
    return await put(`dialogObjects/${id}/name`, {
        name
    })
}

export const updateDialogObject = async (id, dialogObject) => {
    return await put(`dialogObjects/${id}`, dialogObject)
}