import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { useClassNames } from "../../hooks/useClassNames";
import { Link } from 'react-router-dom';

export default function MenuDropdown({ label, disabled, onClick, items}) {
  const classNames = useClassNames();

  return (
    <Menu
      as="div"
      className={classNames.classNames(
        disabled
          ? 'text-gray-400 cursor-not-allowed'
          : 'text-gray-700 cursor-pointer hover:bg-gray-100',
        'relative text-left block px-4 py-2 text-sm ',
      )}
    >
      <Menu.Button
        onClick={disabled && onClick}
        disabled={disabled}
        as="div"
      >
        <span className='select-none'>{label}</span>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
        style={{left: "100%"}}
          className={classNames.classNames(
            'origin-bottom-right w-56 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none right-0 top-0', 'z-10'
          )}
        >
          <div className="py-1">
            {
              items?.map((item, index) => {
                return (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      item.onClick
                        ?
                        <button
                          disabled={item.disabled}
                          onClick={() => item.onClick()}
                          className={classNames.classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm w-full text-left select-none'
                          )}
                        >
                          {item.name}
                        </button>
                        :
                        (
                          item.external === true
                            ? <a
                              href={item.to}
                              target="_blank"
                              rel='noreferrer'
                              className={classNames.classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm w-full text-left'
                              )}
                            >
                              {item.name}
                            </a>
                            : <Link
                              to={item.to}
                              target="_blank"
                              className={classNames.classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm w-full text-left'
                              )}
                            >
                              {item.name}
                            </Link>
                        )
                    )}
                  </Menu.Item>
                )
              })
            }
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}