import { Button as FormButton } from "../Form/Button";

export const ButtonWithLabel = ({ onClick, label, text, disabled = false, isActive = false }) => {

  return (
    <>
    <div className="px-4 sm:px-6 mt-2 flex items-center justify-between">
      <span className="flex-grow flex flex-col" id="availability-label">
        <span className="text-sm font-medium text-gray-500">{label}</span>
      </span>
      <div className="mt-1 relative rounded-md shadow-sm">
        <FormButton
          onClick={onClick}
          text={text}
          theme="white"
          disabled={disabled}
          isActive={isActive}
        />
      </div>
    </div>
    </>
  );
}