import { testDataImage } from './test-data-images'

export const testDataDinaShowHide = {
      dina: {
        type: 2,
        space: 1,
        size: 1,
        backgroundColor: '#edf6ed',
        containers: [
        {
            static: true,
                rows: [
                    {
                        orientation: 0,
                        borderWidth: 0,
                        columns: [
                            {
                                elements: [
                                    {
                                        type: 4,
                                        property: 'Logo',
                                        value: testDataImage.dina,
                                        placeholder: 'Dina Logo',
                                        width: 190,
                                        layout: {
                                            order: 5,
                                            right: false
                                        },
                                        styles: {
                                            borderWidth: 0
                                        }
                                    }]
                            },
                            {
                                elements: [
                                    ]
                            }]
                    },
                    ],
                },
          {
          rows: [
            {
                margin: '30px 0 0 0',
                backgroundColor: '#44a748',
                borderWidth: 0,
                roundedCorners: true,
                space: 5,
                columns: [
                    {
                        space: 3,
                        elements: [
                            {
                              type: 3,
                              property: 'test',
                              value: 'MINA DJUR',
                              layout: {
                                  order: 2,
                              },
                              styles: {
                                  borderWidth: 0
                              },
                              text: {
                                  level: '1',
                                  alignment: 'left',
                                  fontFamily: 'sans-serif',
                                  size: 34,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: '#fff'
                              }
                            },
                            {
                              type: 0,
                              property: 'test',
                              value: 'Om ditt djur är sjukt eller skadat kontaktar du i första hand veterinär eller djursjukhus. När du fått hjälp anmäler du sedan skadan till oss via någon av kategorierna nedan.',
                              layout: {
                                  order: 2,
                              },
                              styles: {
                                  borderWidth: 0
                              },
                              text: {
                                  level: '1',
                                  alignment: 'left',
                                  fontFamily: 'sans-serif',
                                  size: 18,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: '#fff'
                              }
                            },
                            {
                              type: 0,
                              property: 'test',
                              value: 'På djursjukhus kan de ibland göra en så kallad direktreglering och ta hand om din skadeanmälan på plats. Har du fått hjälp med det behöver du inte anmäla skadan här också.',
                              layout: {
                                  order: 2,
                              },
                              styles: {
                                  borderWidth: 0
                              },
                              text: {
                                  level: '1',
                                  alignment: 'left',
                                  fontFamily: 'sans-serif',
                                  size: 18,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: '#fff'
                              }
                            }
                          ]
                    }
                ]
            },
            {
                margin: '30px 0 0 0',
                roundedCorners: true,
                columns: [{
                    elements: [{
                        type: 16,
                        property: 'checkbox',
                        trailingText: 'other background on checked',
                        backgroundColor: '#ff6e70',
                        value: '',
                        layout: {
                            order: 2,
                        },
                        flowOptions: [
                          {name: 'Veterinärvård hund och katt', description: 'Description 1 Description 1 Description 1 Description 1', value: '1', startContainer: 2, stopContainer: 8},
                          {name: 'Liv hund och katt', description: 'Description 2 Description 2 Description 2 Description 2', value: '2', startContainer: 9, stopContainer: 15},
                          {name: 'Veterinärvård häst', description: 'Description 3 Description 3 Description 3 Description 3', value: '3', startContainer: 16, stopContainer: 22},
                        ],
                        text: {
                            level: '1',
                            alignment: 'center',
                            fontFamily: 'arial',
                            size: 18,
                            bold: false,
                            italic: false,
                            underline: false,
                            color: '#fff'
                        }
                    }]
                }]
            }
            ],
          },

          //VETERINÄRVÅRD HUND OCH KATT START

          {
            rows: [
              {
                  margin: '30px 0 0 0',
                  roundedCorners: true,
                  backgroundColor: '#44a748',
                  borderWidth: 0,
                  roundedCorners: true,
                  space: 5,
                  columns: [
                      {
                          space: 3,
                          elements: [
                              {
                                type: 3,
                                property: 'test',
                                value: 'VETERINÄRVÅRD HUND OCH KATT',
                                layout: {
                                    order: 2,
                                },
                                styles: {
                                    borderWidth: 0
                                },
                                text: {
                                    level: '1',
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 34,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#fff'
                                }
                              },
                              {
                                type: 0,
                                property: 'test',
                                leadingIcon: 2,
                                value: 'Vill du komplettera en redan anmäld skada eller har du andra frågor? Kontakta oss på hundkatt@dina.se eller 020-319 000.',
                                layout: {
                                    order: 2,
                                },
                                styles: {
                                    borderWidth: 0
                                },
                                text: {
                                    level: '1',
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 18,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#fff'
                                }
                              },
 
                            ]
                      }
                  ]
              }
            ]
            }, 

            {
              roundedCorners: true,
              margin: '30px 0 0 0',
            rows: [
            {
                backgroundColor: '#44a748',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 3,
                        elements: [
                            {
                              type: 3,
                              property: 'test',
                              value: 'Om försäkringstagaren',
                              layout: {
                                  order: 2,
                              },
                              styles: {
                                  borderWidth: 0
                              },
                              text: {
                                  level: '2',
                                  alignment: 'left',
                                  fontFamily: 'sans-serif',
                                  size: 20,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: '#fff'
                              }
                            }
                          ]
                    }
                ]
            },
            {
                margin: '0',
                roundedCorners: true,
                backgroundColor: '#fff',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 10,
                        elements: [
                            {
                                type: 2,
                                inputType: 1,
                                property: 'test',
                                value: 'Försäkringsnummer',
                                label: 'Försäkringsnummer',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 1,
                                property: 'test',
                                value: 'Namn',
                                label: 'Namn',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 2,
                                property: 'test',
                                value: 'Personnummer',
                                label: 'Personnummer',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 1,
                                property: 'test',
                                value: 'E-postadress',
                                label: 'E-postadress',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 2,
                                property: 'test',
                                value: 'Telefonnummer',
                                label: 'Telefonnummer',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            }
                        ]
                    }
                ]
            },
            ],
          },
            {
              margin: '30px 0 0 0',
              roundedCorners: true,
            rows: [
            {
                backgroundColor: '#44a748',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 3,
                        elements: [
                            {
                              type: 3,
                              property: 'test',
                              value: 'Uppgifter om djuret',
                              layout: {
                                  order: 2,
                              },
                              styles: {
                                  borderWidth: 0
                              },
                              text: {
                                  level: '2',
                                  alignment: 'left',
                                  fontFamily: 'sans-serif',
                                  size: 20,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: '#fff'
                              }
                            }
                          ]
                    }
                ]
            },
            {
                margin: '0',
                roundedCorners: true,
                backgroundColor: '#fff',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 10,
                        elements: [
                            {
                                type: 13,
                                inputType: 2,
                                property: 'payout',
                                value: 'Skadeanmälan gäller',
                                label: 'Skadeanmälan gäller',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                options: [
                                  'Katt',
                                  'Hund'
                                ],
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 1,
                                property: 'test',
                                value: 'Djurets namn',
                                label: 'Djurets namn',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 5,
                                inputType: 1,
                                property: 'test',
                                value: 'Födelsedatum? ',
                                label: 'Födelsedatum? ',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 1,
                                property: 'test',
                                value: 'Ras? ',
                                label: 'Ras? ',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 1,
                                property: 'test',
                                value: 'Kön? ',
                                label: 'Kön? ',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 1,
                                property: 'test',
                                value: 'Ange registrerings- eller chipnummer ',
                                label: 'Ange registrerings- eller chipnummer ',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                           
                            {
                                type: 2,
                                inputType: 1,
                                property: 'test',
                                value: 'Ange inköpsdatum och pris',
                                label: 'Ange inköpsdatum och pris',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                leadingText: 'kr',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                        ]
                    }
                ]
            },
            ],
          },
            {
              margin: '30px 0 0 0',
              roundedCorners: true,
            rows: [
            {
                backgroundColor: '#44a748',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 3,
                        elements: [
                            {
                              type: 3,
                              property: 'test',
                              value: 'Uppgifter om skadan',
                              layout: {
                                  order: 2,
                              },
                              styles: {
                                  borderWidth: 0
                              },
                              text: {
                                  level: '2',
                                  alignment: 'left',
                                  fontFamily: 'sans-serif',
                                  size: 20,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: '#fff'
                              }
                            }
                          ]
                    }
                ]
            },
            {
                margin: '0',
                roundedCorners: true,
                backgroundColor: '#fff',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 10,
                        elements: [
                            {
                                type: 1,
                                inputType: 1,
                                property: 'test',
                                value: 'Beskriv när och hur skadan eller sjukdomen visade sig och hur den utvecklades',
                                label: 'Beskriv när och hur skadan eller sjukdomen visade sig och hur den utvecklades',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 2,
                                property: 'test',
                                value: 'Personnummer',
                                label: 'Om djuret haft liknande symtom tidigare, ange när',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 2,
                                property: 'test',
                                value: 'Personnummer',
                                label: 'Ange veterinärer och/eller kliniker som anlitats för ovanstående sjukdom, och datum när ',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 2,
                                property: 'test',
                                value: 'Personnummer',
                                label: 'Om det är en trafikskada, ange fordonets registreringsnummer och försäkringsbolag samt förarens namn (om känt) ',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 13,
                                inputType: 2,
                                property: 'payout',
                                value: 'Skadeanmälan gäller',
                                label: 'Har polisanmälan gjorts?',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                options: [
                                  'Ja',
                                  'Nei'
                                ],
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            }
                        ]
                    }
                ]
            },
            ],
          },
            {
              margin: '30px 0 0 0',
              roundedCorners: true,
            rows: [
            {
                backgroundColor: '#44a748',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 3,
                        elements: [
                            {
                              type: 3,
                              property: 'test',
                              value: 'Övriga uppgifter',
                              layout: {
                                  order: 2,
                              },
                              styles: {
                                  borderWidth: 0
                              },
                              text: {
                                  level: '2',
                                  alignment: 'left',
                                  fontFamily: 'sans-serif',
                                  size: 20,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: '#fff'
                              }
                            }
                          ]
                    }
                ]
            },
            {
                margin: '0',
                roundedCorners: true,
                backgroundColor: '#fff',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 10,
                        elements: [
                            {
                                type: 13,
                                inputType: 2,
                                property: 'payout',
                                value: 'payout',
                                label: 'Ersättning önskas till:',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                options: [
                                  'Bankkonto',
                                  'Plusgiro',
                                  'Bankgiro'
                                ],
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 1,
                                property: 'test',
                                value: 'E-postadress',
                                label: 'Kontonummer inklusive clearingnummer',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 2,
                                property: 'test',
                                value: 'Telefonnummer',
                                label: 'Tillhör kontonumret försäkringstagaren? Om inte, ange kontoinnehavarens namn och personnummer: ',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 1,
                                inputType: 2,
                                property: 'test',
                                value: 'Telefonnummer',
                                label: 'Övriga kommentarer ',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                        ]
                    }
                ]
            },
            ],
          },
            {
              margin: '30px 0 0 0',
              roundedCorners: true,
            rows: [
            {
                backgroundColor: '#44a748',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 3,
                        elements: [
                            {
                              type: 3,
                              property: 'test',
                              value: 'Bifoga filer',
                              layout: {
                                  order: 2,
                              },
                              styles: {
                                  borderWidth: 0
                              },
                              text: {
                                  level: '2',
                                  alignment: 'left',
                                  fontFamily: 'sans-serif',
                                  size: 20,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: '#fff'
                              }
                            }
                          ]
                    }
                ]
            },
            {
                margin: '0',
                roundedCorners: true,
                backgroundColor: '#fff',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 0,
                        elements: [
                            {
                                type: 0,
                                inputType: 1,
                                property: 'test',
                                value: 'Några saker att tänka på:',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: true,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 0,
                                inputType: 1,
                                property: 'test',
                                value: '- Tar du bilder med din telefon, ladda inte upp dem direkt utan spara i telefonens album först.',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 18,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 0,
                                inputType: 1,
                                property: 'test',
                                value: '- Alla filerna måste ha unika namn.',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 18,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 12,
                                inputType: 2,
                                property: 'test',
                                value: 'Personnummer',
                                label: 'Ange skadebelopp i kronor:',
                                placeholder: '',
                                margin: '30px 0 0 0',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            }
                        ]
                    }
                ]
            },
            ],
          },
            {
              margin: '30px 0 0 0',
              lastQuestionInFlow: true,
              roundedCorners: true,
            rows: [
            {
                padding: '40px 30px',
                backgroundColor: '#fff',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 0,
                        elements: [
                            {
                                type: 14,
                                inputType: 1,
                                property: 'test',
                                value: 'Jag har läst och godkänner Dina Försäkringars hantering av mina personuppgifter.',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: true,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            }
                        ]
                    }
                ]
            }
        ]

        //VETERINÄRVÅRD HUND OCH KATT STOP
      },
      {
        rows: [
          {
              margin: '30px 0 0 0',
              backgroundColor: '#44a748',
              borderWidth: 0,
              roundedCorners: true,
              space: 5,
              columns: [
                  {
                      space: 3,
                      elements: [
                          {
                            type: 3,
                            property: 'test',
                            value: 'LIV HUND OCH KATT',
                            layout: {
                                order: 2,
                            },
                            styles: {
                                borderWidth: 0
                            },
                            text: {
                                level: '1',
                                alignment: 'left',
                                fontFamily: 'sans-serif',
                                size: 34,
                                bold: false,
                                italic: false,
                                underline: false,
                                color: '#fff'
                            }
                          },
                          {
                            type: 0,
                            property: 'test',
                            value: 'Ta en bild av den fullständiga journalen eller livintyget och bifoga. ',
                            layout: {
                                order: 2,
                            },
                            styles: {
                                borderWidth: 0
                            },
                            text: {
                                level: '1',
                                alignment: 'left',
                                fontFamily: 'sans-serif',
                                size: 18,
                                bold: false,
                                italic: false,
                                underline: false,
                                color: '#fff'
                            }
                          },
                          {
                            type: 0,
                            property: 'test',
                            value: 'OBS: Kontakta oss snarast om djuret har dött eller avlivats utan att en veterinär har varit inblandad. Begrav eller kremera inte innan du pratat med oss.',
                            layout: {
                                order: 2,
                            },
                            styles: {
                                borderWidth: 0
                            },
                            text: {
                                level: '1',
                                alignment: 'left',
                                fontFamily: 'sans-serif',
                                size: 18,
                                bold: false,
                                italic: false,
                                underline: false,
                                color: '#fff'
                            }
                          },
                          {
                            type: 0,
                            property: 'test',
                            leadingIcon: 2,
                            value: 'Vill du komplettera en redan anmäld skada eller har du andra frågor? Kontakta oss på hundkatt@dina.se eller 020-319 000.',
                            layout: {
                                order: 2,
                            },
                            styles: {
                                borderWidth: 0
                            },
                            text: {
                                level: '1',
                                alignment: 'left',
                                fontFamily: 'sans-serif',
                                size: 18,
                                bold: false,
                                italic: false,
                                underline: false,
                                color: '#fff'
                            }
                          }

                        ]
                  }
              ]
          }
        ]
        },
      {
              roundedCorners: true,
              margin: '30px 0 0 0',
            rows: [
            {
                backgroundColor: '#44a748',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 3,
                        elements: [
                            {
                              type: 3,
                              property: 'test',
                              value: 'Om försäkringstagaren',
                              layout: {
                                  order: 2,
                              },
                              styles: {
                                  borderWidth: 0
                              },
                              text: {
                                  level: '2',
                                  alignment: 'left',
                                  fontFamily: 'sans-serif',
                                  size: 20,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: '#fff'
                              }
                            }
                          ]
                    }
                ]
            },
            {
                margin: '0',
                roundedCorners: true,
                backgroundColor: '#fff',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 10,
                        elements: [
                            {
                                type: 2,
                                inputType: 1,
                                property: 'test',
                                value: 'Försäkringsnummer',
                                label: 'Försäkringsnummer',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 1,
                                property: 'test',
                                value: 'Namn',
                                label: 'Namn',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 2,
                                property: 'test',
                                value: 'Personnummer',
                                label: 'Personnummer',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 1,
                                property: 'test',
                                value: 'E-postadress',
                                label: 'E-postadress',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 2,
                                property: 'test',
                                value: 'Telefonnummer',
                                label: 'Telefonnummer',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            }
                        ]
                    }
                ]
            },
            ],
          },
            {
              margin: '30px 0 0 0',
              roundedCorners: true,
            rows: [
            {
                backgroundColor: '#44a748',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 3,
                        elements: [
                            {
                              type: 3,
                              property: 'test',
                              value: 'Uppgifter om djuret',
                              layout: {
                                  order: 2,
                              },
                              styles: {
                                  borderWidth: 0
                              },
                              text: {
                                  level: '2',
                                  alignment: 'left',
                                  fontFamily: 'sans-serif',
                                  size: 20,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: '#fff'
                              }
                            }
                          ]
                    }
                ]
            },
            {
                margin: '0',
                roundedCorners: true,
                backgroundColor: '#fff',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 10,
                        elements: [
                            {
                                type: 13,
                                inputType: 2,
                                property: 'payout',
                                value: 'Skadeanmälan gäller',
                                label: 'Skadeanmälan gäller',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                options: [
                                  'Katt',
                                  'Hund'
                                ],
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 1,
                                property: 'test',
                                value: 'Djurets namn',
                                label: 'Djurets namn',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 5,
                                inputType: 1,
                                property: 'test',
                                value: 'Födelsedatum? ',
                                label: 'Födelsedatum? ',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 1,
                                property: 'test',
                                value: 'Ras? ',
                                label: 'Ras? ',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 1,
                                property: 'test',
                                value: 'Kön? ',
                                label: 'Kön? ',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 1,
                                property: 'test',
                                value: 'Ange registrerings- eller chipnummer ',
                                label: 'Ange registrerings- eller chipnummer ',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                           
                            {
                                type: 2,
                                inputType: 1,
                                property: 'test',
                                value: 'Ange inköpsdatum och pris',
                                label: 'Ange inköpsdatum och pris',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                leadingText: 'kr',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                        ]
                    }
                ]
            },
            ],
          },
            {
              margin: '30px 0 0 0',
              roundedCorners: true,
            rows: [
            {
                backgroundColor: '#44a748',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 3,
                        elements: [
                            {
                              type: 3,
                              property: 'test',
                              value: 'Uppgifter om skadan',
                              layout: {
                                  order: 2,
                              },
                              styles: {
                                  borderWidth: 0
                              },
                              text: {
                                  level: '2',
                                  alignment: 'left',
                                  fontFamily: 'sans-serif',
                                  size: 20,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: '#fff'
                              }
                            }
                          ]
                    }
                ]
            },
            {
                margin: '0',
                roundedCorners: true,
                backgroundColor: '#fff',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 10,
                        elements: [
                            {
                                type: 1,
                                inputType: 1,
                                property: 'test',
                                value: 'Beskriv när och hur skadan eller sjukdomen visade sig och hur den utvecklades',
                                label: 'Om veterinär eller klinik anlitats, ange datum och namn på veterinär eller klinik ',
                                placeholder: '',
                                rows: 2,
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 1,
                                inputType: 1,
                                property: 'test',
                                value: 'Beskriv när och hur skadan eller sjukdomen visade sig och hur den utvecklades',
                                label: 'Beskriv när och hur skadan eller sjukdomen visade sig och hur den utvecklades',
                                placeholder: '',
                                rows: 2,
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 2,
                                property: 'test',
                                value: 'Personnummer',
                                label: 'Om anmälan gäller försvunnen hund, ange datum när hunden försvann ',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 2,
                                property: 'test',
                                value: 'Personnummer',
                                label: 'Om djuret är dött eller avlivats, ange datum ',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 2,
                                property: 'test',
                                value: 'Personnummer',
                                label: 'Om det är en trafikskada, ange fordonets registreringsnummer och försäkringsbolag ',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 13,
                                inputType: 2,
                                property: 'payout',
                                value: 'Skadeanmälan gäller',
                                label: 'Om det är en trafikskada, har polisanmälan gjorts?',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                options: [
                                  'Ja',
                                  'Nei'
                                ],
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            }
                        ]
                    }
                ]
            },
            ],
          },
            {
              margin: '30px 0 0 0',
              roundedCorners: true,
            rows: [
            {
                backgroundColor: '#44a748',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 3,
                        elements: [
                            {
                              type: 3,
                              property: 'test',
                              value: 'Övriga uppgifter',
                              layout: {
                                  order: 2,
                              },
                              styles: {
                                  borderWidth: 0
                              },
                              text: {
                                  level: '2',
                                  alignment: 'left',
                                  fontFamily: 'sans-serif',
                                  size: 20,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: '#fff'
                              }
                            }
                          ]
                    }
                ]
            },
            {
                margin: '0',
                roundedCorners: true,
                backgroundColor: '#fff',
                borderWidth: 0,
                roundedCorners: true,
                space: 5,
                columns: [
                    {
                        space: 10,
                        elements: [
                            {
                                type: 13,
                                inputType: 2,
                                property: 'payout',
                                value: 'payout',
                                label: 'Ersättning önskas till:',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                options: [
                                  'Bankkonto',
                                  'Plusgiro',
                                  'Bankgiro'
                                ],
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 1,
                                property: 'test',
                                value: 'E-postadress',
                                label: 'Kontonummer inklusive clearingnummer',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 2,
                                property: 'test',
                                value: 'Telefonnummer',
                                label: 'Tillhör kontonumret försäkringstagaren? Om inte, ange kontoinnehavarens namn och personnummer: ',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 13,
                                inputType: 2,
                                property: 'payout',
                                value: 'payout',
                                label: 'Är du redovisningsskyldig för moms?',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                options: [
                                  'Ja',
                                  'Nei',
                                ],
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 1,
                                inputType: 2,
                                property: 'test',
                                value: 'Telefonnummer',
                                label: 'Övriga kommentarer ',
                                placeholder: '',
                                rows: 3,
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                        ]
                    }
                ]
            },
            ],
          },
            {
              margin: '30px 0 0 0',
              roundedCorners: true,
            rows: [
            {
                backgroundColor: '#44a748',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 3,
                        elements: [
                            {
                              type: 3,
                              property: 'test',
                              value: 'Bifoga filer',
                              layout: {
                                  order: 2,
                              },
                              styles: {
                                  borderWidth: 0
                              },
                              text: {
                                  level: '2',
                                  alignment: 'left',
                                  fontFamily: 'sans-serif',
                                  size: 20,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: '#fff'
                              }
                            }
                          ]
                    }
                ]
            },
            {
                margin: '0',
                roundedCorners: true,
                backgroundColor: '#fff',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 0,
                        elements: [
                            {
                                type: 0,
                                inputType: 1,
                                property: 'test',
                                value: 'Några saker att tänka på:',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: true,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 0,
                                inputType: 1,
                                property: 'test',
                                value: '- Tar du bilder med din telefon, ladda inte upp dem direkt utan spara i telefonens album först.',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 18,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 0,
                                inputType: 1,
                                property: 'test',
                                value: '- Alla filerna måste ha unika namn.',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 18,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 12,
                                inputType: 2,
                                property: 'test',
                                value: 'Personnummer',
                                label: 'Ange skadebelopp i kronor:',
                                placeholder: '',
                                margin: '30px 0 0 0',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            }
                        ]
                    }
                ]
            },
            ],
          },
            {
              margin: '30px 0 0 0',
              lastQuestionInFlow: true,
              roundedCorners: true,
            rows: [
            {
                padding: '40px 30px',
                backgroundColor: '#fff',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 0,
                        elements: [
                            {
                                type: 14,
                                inputType: 1,
                                property: 'test',
                                value: 'Jag har läst och godkänner Dina Försäkringars hantering av mina personuppgifter.',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: true,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            }
                        ]
                    }
                ]
            }
        ]

        //VETERINÄRVÅRD HUND OCH KATT STOP
      },

       // Something else start

       {
        rows: [
          {
              margin: '30px 0 0 0',
              backgroundColor: '#44a748',
              borderWidth: 0,
              roundedCorners: true,
              space: 5,
              columns: [
                  {
                      space: 3,
                      elements: [
                          {
                            type: 3,
                            property: 'test',
                            value: 'VETERINÄRVÅRD HÄST',
                            layout: {
                                order: 2,
                            },
                            styles: {
                                borderWidth: 0
                            },
                            text: {
                                level: '1',
                                alignment: 'left',
                                fontFamily: 'sans-serif',
                                size: 34,
                                bold: false,
                                italic: false,
                                underline: false,
                                color: '#fff'
                            }
                          },
                          {
                            type: 0,
                            property: 'test',
                            leadingIcon: 0,
                            value: 'Anmälan tar cirka 5 minuter att fylla i.',
                            layout: {
                                order: 2,
                            },
                            styles: {
                                borderWidth: 0
                            },
                            text: {
                                level: '1',
                                alignment: 'left',
                                fontFamily: 'sans-serif',
                                size: 18,
                                bold: false,
                                italic: false,
                                underline: false,
                                color: '#fff'
                            }
                          },
                          {
                            type: 0,
                            property: 'test',
                            leadingIcon: 2,
                            value: 'Vill du komplettera en redan anmäld skada eller har du andra frågor? Kontakta oss på hundkatt@dina.se eller 020-319 000.',
                            layout: {
                                order: 2,
                            },
                            styles: {
                                borderWidth: 0
                            },
                            text: {
                                level: '1',
                                alignment: 'left',
                                fontFamily: 'sans-serif',
                                size: 18,
                                bold: false,
                                italic: false,
                                underline: false,
                                color: '#fff'
                            }
                          }

                        ]
                  }
              ]
          }
        ]
        },
       {
        roundedCorners: true,
        margin: '30px 0 0 0',
      rows: [
      {
          backgroundColor: '#44a748',
          borderWidth: 0,
          space: 5,
          columns: [
              {
                  space: 3,
                  elements: [
                      {
                        type: 3,
                        property: 'test',
                        value: 'Om försäkringstagaren',
                        layout: {
                            order: 2,
                        },
                        styles: {
                            borderWidth: 0
                        },
                        text: {
                            level: '2',
                            alignment: 'left',
                            fontFamily: 'sans-serif',
                            size: 20,
                            bold: false,
                            italic: false,
                            underline: false,
                            color: '#fff'
                        }
                      }
                    ]
              }
          ]
      },
      {
          margin: '0',
          roundedCorners: true,
          backgroundColor: '#fff',
          borderWidth: 0,
          space: 5,
          columns: [
              {
                  space: 10,
                  elements: [
                      {
                          type: 2,
                          inputType: 1,
                          property: 'test',
                          value: 'Försäkringsnummer',
                          label: 'Försäkringsnummer',
                          placeholder: '',
                          borderWidth: '1px',
                          borderColor: '#e5e7eb',
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'center',
                              fontFamily: 'sans-serif',
                              size: 20,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      },
                      {
                          type: 2,
                          inputType: 1,
                          property: 'test',
                          value: 'Namn',
                          label: 'Namn',
                          placeholder: '',
                          borderWidth: '1px',
                          borderColor: '#e5e7eb',
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'center',
                              fontFamily: 'sans-serif',
                              size: 20,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      },
                      {
                          type: 2,
                          inputType: 2,
                          property: 'test',
                          value: 'Personnummer',
                          label: 'Personnummer',
                          placeholder: '',
                          borderWidth: '1px',
                          borderColor: '#e5e7eb',
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'center',
                              fontFamily: 'sans-serif',
                              size: 20,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      },
                      {
                          type: 2,
                          inputType: 1,
                          property: 'test',
                          value: 'E-postadress',
                          label: 'E-postadress',
                          placeholder: '',
                          borderWidth: '1px',
                          borderColor: '#e5e7eb',
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'center',
                              fontFamily: 'sans-serif',
                              size: 20,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      },
                      {
                          type: 2,
                          inputType: 2,
                          property: 'test',
                          value: 'Telefonnummer',
                          label: 'Telefonnummer',
                          placeholder: '',
                          borderWidth: '1px',
                          borderColor: '#e5e7eb',
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'center',
                              fontFamily: 'sans-serif',
                              size: 20,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      }
                  ]
              }
          ]
      },
      ],
    },
      {
        margin: '30px 0 0 0',
        roundedCorners: true,
      rows: [
      {
          backgroundColor: '#44a748',
          borderWidth: 0,
          space: 5,
          columns: [
              {
                  space: 3,
                  elements: [
                      {
                        type: 3,
                        property: 'test',
                        value: 'Uppgifter om djuret',
                        layout: {
                            order: 2,
                        },
                        styles: {
                            borderWidth: 0
                        },
                        text: {
                            level: '2',
                            alignment: 'left',
                            fontFamily: 'sans-serif',
                            size: 20,
                            bold: false,
                            italic: false,
                            underline: false,
                            color: '#fff'
                        }
                      }
                    ]
              }
          ]
      },
      {
          margin: '0',
          roundedCorners: true,
          backgroundColor: '#fff',
          borderWidth: 0,
          space: 5,
          columns: [
              {
                  space: 10,
                  elements: [
                      {
                          type: 13,
                          inputType: 2,
                          property: 'payout',
                          value: 'Skadeanmälan gäller',
                          label: 'Skadeanmälan gäller',
                          placeholder: '',
                          borderWidth: '1px',
                          borderColor: '#e5e7eb',
                          options: [
                            'Häst',
                            'Foster/föl'
                          ],
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'center',
                              fontFamily: 'sans-serif',
                              size: 20,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      },
                      {
                          type: 2,
                          inputType: 1,
                          property: 'test',
                          value: 'Djurets namn',
                          label: 'Hästens namn',
                          placeholder: '',
                          borderWidth: '1px',
                          borderColor: '#e5e7eb',
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'center',
                              fontFamily: 'sans-serif',
                              size: 20,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      },
                      {
                          type: 5,
                          inputType: 1,
                          property: 'test',
                          value: 'Födelsedatum? ',
                          label: 'Födelsedatum? ',
                          placeholder: '',
                          borderWidth: '1px',
                          borderColor: '#e5e7eb',
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'center',
                              fontFamily: 'sans-serif',
                              size: 20,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      },
                      {
                          type: 2,
                          inputType: 1,
                          property: 'test',
                          value: 'Ras? ',
                          label: 'Ras? ',
                          placeholder: '',
                          borderWidth: '1px',
                          borderColor: '#e5e7eb',
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'center',
                              fontFamily: 'sans-serif',
                              size: 20,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      },
                      {
                          type: 2,
                          inputType: 1,
                          property: 'test',
                          value: 'Kön? ',
                          label: 'Kön? ',
                          placeholder: '',
                          borderWidth: '1px',
                          borderColor: '#e5e7eb',
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'center',
                              fontFamily: 'sans-serif',
                              size: 20,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      },
                      {
                          type: 2,
                          inputType: 1,
                          property: 'test',
                          value: 'Ange registrerings- eller chipnummer ',
                          label: 'Ange registrerings- eller chipnummer ',
                          placeholder: '',
                          borderWidth: '1px',
                          borderColor: '#e5e7eb',
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'center',
                              fontFamily: 'sans-serif',
                              size: 20,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      },
                     
                      {
                          type: 2,
                          inputType: 1,
                          property: 'test',
                          value: 'Ange inköpsdatum och pris',
                          label: 'Ange inköpsdatum och pris',
                          placeholder: '',
                          borderWidth: '1px',
                          borderColor: '#e5e7eb',
                          leadingText: 'kr',
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'center',
                              fontFamily: 'sans-serif',
                              size: 20,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      },
                  ]
              }
          ]
      },
      ],
    },
      {
        margin: '30px 0 0 0',
        roundedCorners: true,
      rows: [
      {
          backgroundColor: '#44a748',
          borderWidth: 0,
          space: 5,
          columns: [
              {
                  space: 3,
                  elements: [
                      {
                        type: 3,
                        property: 'test',
                        value: 'Uppgifter om skadan',
                        layout: {
                            order: 2,
                        },
                        styles: {
                            borderWidth: 0
                        },
                        text: {
                            level: '2',
                            alignment: 'left',
                            fontFamily: 'sans-serif',
                            size: 20,
                            bold: false,
                            italic: false,
                            underline: false,
                            color: '#fff'
                        }
                      }
                    ]
              }
          ]
      },
      {
          margin: '0',
          roundedCorners: true,
          backgroundColor: '#fff',
          borderWidth: 0,
          space: 5,
          columns: [
              {
                  space: 10,
                  elements: [
                      {
                          type: 1,
                          inputType: 1,
                          property: 'test',
                          value: 'Beskriv när och hur skadan eller sjukdomen visade sig och hur den utvecklades',
                          label: 'Beskriv när och hur skadan eller sjukdomen visade sig och hur den utvecklades',
                          placeholder: '',
                          borderWidth: '1px',
                          borderColor: '#e5e7eb',
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'center',
                              fontFamily: 'sans-serif',
                              size: 20,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      },
                      {
                          type: 2,
                          inputType: 2,
                          property: 'test',
                          value: 'Personnummer',
                          label: 'Om hästen haft liknande symtom tidigare, ange när ',
                          placeholder: '',
                          borderWidth: '1px',
                          borderColor: '#e5e7eb',
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'center',
                              fontFamily: 'sans-serif',
                              size: 20,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      },
                      {
                          type: 2,
                          inputType: 2,
                          property: 'test',
                          value: 'Personnummer',
                          label: 'Ange veterinärer och/eller kliniker som anlitats för ovanstående sjukdom, och datum när ',
                          placeholder: '',
                          borderWidth: '1px',
                          borderColor: '#e5e7eb',
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'center',
                              fontFamily: 'sans-serif',
                              size: 20,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      },
                      {
                          type: 2,
                          inputType: 2,
                          property: 'test',
                          value: 'Personnummer',
                          label: 'Om det är en trafikskada, ange fordonets registreringsnummer och försäkringsbolag samt förarens namn (om känt) ',
                          placeholder: '',
                          borderWidth: '1px',
                          borderColor: '#e5e7eb',
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'center',
                              fontFamily: 'sans-serif',
                              size: 20,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      },
                      {
                          type: 13,
                          inputType: 2,
                          property: 'payout',
                          value: 'Skadeanmälan gäller',
                          label: 'Har polisanmälan gjorts?',
                          placeholder: '',
                          borderWidth: '1px',
                          borderColor: '#e5e7eb',
                          options: [
                            'Ja',
                            'Nei'
                          ],
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'center',
                              fontFamily: 'sans-serif',
                              size: 20,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      }
                  ]
              }
          ]
      },
      ],
    },
      {
        margin: '30px 0 0 0',
        roundedCorners: true,
      rows: [
      {
          backgroundColor: '#44a748',
          borderWidth: 0,
          space: 5,
          columns: [
              {
                  space: 3,
                  elements: [
                      {
                        type: 3,
                        property: 'test',
                        value: 'Övriga uppgifter',
                        layout: {
                            order: 2,
                        },
                        styles: {
                            borderWidth: 0
                        },
                        text: {
                            level: '2',
                            alignment: 'left',
                            fontFamily: 'sans-serif',
                            size: 20,
                            bold: false,
                            italic: false,
                            underline: false,
                            color: '#fff'
                        }
                      }
                    ]
              }
          ]
      },
      {
          margin: '0',
          roundedCorners: true,
          backgroundColor: '#fff',
          borderWidth: 0,
          space: 5,
          columns: [
              {
                  space: 10,
                  elements: [
                      {
                          type: 13,
                          inputType: 2,
                          property: 'payout',
                          value: 'payout',
                          label: 'Ersättning önskas till:',
                          placeholder: '',
                          borderWidth: '1px',
                          borderColor: '#e5e7eb',
                          options: [
                            'Bankkonto',
                            'Plusgiro',
                            'Bankgiro'
                          ],
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'center',
                              fontFamily: 'sans-serif',
                              size: 20,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      },
                      {
                          type: 2,
                          inputType: 1,
                          property: 'test',
                          value: 'E-postadress',
                          label: 'Kontonummer inklusive clearingnummer',
                          placeholder: '',
                          borderWidth: '1px',
                          borderColor: '#e5e7eb',
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'center',
                              fontFamily: 'sans-serif',
                              size: 20,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      },
                      {
                          type: 2,
                          inputType: 2,
                          property: 'test',
                          value: 'Telefonnummer',
                          label: 'Tillhör kontonumret försäkringstagaren? Om inte, ange kontoinnehavarens namn och personnummer: ',
                          placeholder: '',
                          borderWidth: '1px',
                          borderColor: '#e5e7eb',
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'center',
                              fontFamily: 'sans-serif',
                              size: 20,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      },
                      {
                        type: 13,
                        inputType: 2,
                        property: 'payout',
                        value: 'payout',
                        label: 'Är du redovisningsskyldig för moms för den drabbade hästen?',
                        placeholder: '',
                        borderWidth: '1px',
                        borderColor: '#e5e7eb',
                        options: [
                          'Ja',
                          'Nei'
                        ],
                        layout: {
                            order: 2,
                        },
                        text: {
                            alignment: 'center',
                            fontFamily: 'sans-serif',
                            size: 20,
                            bold: false,
                            italic: false,
                            underline: false,
                            color: 'black'
                        }
                    },
                      {
                          type: 1,
                          inputType: 2,
                          property: 'test',
                          value: 'Telefonnummer',
                          label: 'Övriga kommentarer ',
                          rows: 3,
                          placeholder: '',
                          borderWidth: '1px',
                          borderColor: '#e5e7eb',
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'center',
                              fontFamily: 'sans-serif',
                              size: 20,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      },
                  ]
              }
          ]
      },
      ],
    },
      {
        margin: '30px 0 0 0',
        roundedCorners: true,
      rows: [
      {
          backgroundColor: '#44a748',
          borderWidth: 0,
          space: 5,
          columns: [
              {
                  space: 3,
                  elements: [
                      {
                        type: 3,
                        property: 'test',
                        value: 'Bifoga filer',
                        layout: {
                            order: 2,
                        },
                        styles: {
                            borderWidth: 0
                        },
                        text: {
                            level: '2',
                            alignment: 'left',
                            fontFamily: 'sans-serif',
                            size: 20,
                            bold: false,
                            italic: false,
                            underline: false,
                            color: '#fff'
                        }
                      }
                    ]
              }
          ]
      },
      {
          margin: '0',
          roundedCorners: true,
          backgroundColor: '#fff',
          borderWidth: 0,
          space: 5,
          columns: [
              {
                  space: 0,
                  elements: [
                      {
                          type: 0,
                          inputType: 1,
                          property: 'test',
                          value: 'Några saker att tänka på:',
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'left',
                              fontFamily: 'sans-serif',
                              size: 20,
                              bold: true,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      },
                      {
                          type: 0,
                          inputType: 1,
                          property: 'test',
                          value: '- Tar du bilder med din telefon, ladda inte upp dem direkt utan spara i telefonens album först.',
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'left',
                              fontFamily: 'sans-serif',
                              size: 18,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      },
                      {
                          type: 0,
                          inputType: 1,
                          property: 'test',
                          value: '- Alla filerna måste ha unika namn.',
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'left',
                              fontFamily: 'sans-serif',
                              size: 18,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      },
                      {
                          type: 12,
                          inputType: 2,
                          property: 'test',
                          value: 'Personnummer',
                          label: 'Ange skadebelopp i kronor:',
                          placeholder: '',
                          margin: '30px 0 0 0',
                          borderWidth: '1px',
                          borderColor: '#e5e7eb',
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'center',
                              fontFamily: 'sans-serif',
                              size: 20,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      }
                  ]
              }
          ]
      },
      ],
    },
      {
        margin: '30px 0 0 0',
        lastQuestionInFlow: true,
        roundedCorners: true,
      rows: [
      {
          padding: '40px 30px',
          backgroundColor: '#fff',
          borderWidth: 0,
          space: 5,
          columns: [
              {
                  space: 0,
                  elements: [
                      {
                          type: 14,
                          inputType: 1,
                          property: 'test',
                          value: 'Jag har läst och godkänner Dina Försäkringars hantering av mina personuppgifter.',
                          layout: {
                              order: 2,
                          },
                          text: {
                              alignment: 'left',
                              fontFamily: 'sans-serif',
                              size: 20,
                              bold: true,
                              italic: false,
                              underline: false,
                              color: 'black'
                          }
                      }
                  ]
              }
          ]
      }
  ]

  //VETERINÄRVÅRD HUND OCH KATT STOP
}

    ]
    },
}
