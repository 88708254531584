import { Button as FormButton } from "../Form/Button";

export const Button = ({ onClick, label, prefixText, disabled = false, isActive = false }) => {

  return (
    <>
    {
      prefixText && 
    <div className="mt-2 flex items-center justify-between">
      <span className="flex-grow flex flex-col" id="availability-label">
        <span className="text-sm font-medium text-gray-500">{prefixText}</span>
      </span>
      <div className="mt-1 relative rounded-md shadow-sm">
        <FormButton
          onClick={onClick}
          text={label}
          theme="white"
          disabled={disabled}
          isActive={isActive}
        />
      </div>
    </div>

      }
      
      {
        !prefixText && 
        <FormButton
          onClick={onClick}
          text={label}
          theme="white"
          disabled={disabled}
          isActive={isActive}
        />
    }
    </>
  );
}