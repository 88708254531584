/**
 * Overlay to prevent mouse hover over other things from removing context from currently
 * hovered over thing.
 */
export const Overlay = () => {
  return (
    <div
      className='z-40'
      style={{
        height: '100%',
        width: '100%',
        position: 'fixed',
        left: 0,
        top: 0,
        overflowX: 'hidden',
      }}
    />
  )
}