import { setProperty } from '../utils/property';
import { useSelector } from 'react-redux';

export const useLabelUpdate = (data, setData, actionsHandleChanges) => {
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const onChange = (e, type) => {
    const changes = [e]
    const { name, value } = e.target;
    changes.push({
      target: {
        name: 'labelSetManually',
        value: true
      }
    })

    actionsHandleChanges(dialogDefinition, changes, data.id, type)
    setProperty(data, name, value, type)
    setProperty(data, 'labelSetManually', true)
    setData(data => ({
      ...data
    }));
  }

  return {
    onChange
  }
}