import { useContext } from "react"
import { MenuContext } from "./context"
import { useClassNames } from "../../hooks/useClassNames";

export const MenuItem = ({ children, onClick, disabled }) => {
  const contextMenu = useContext(MenuContext)
  const classNames = useClassNames();

  const handleClick = () => {
    if (!disabled) {
      if (onClick) {
        onClick()
      }
      contextMenu.onContextItemClicked()
    }
  }
  return (
    <div
      onClick={handleClick}
      className={classNames.classNames(
        disabled
          ? 'text-gray-400 cursor-not-allowed'
          : 'text-gray-700 cursor-pointer hover:bg-gray-100',
        'text-left block px-4 py-2 text-sm ',
      )}
    >
      {children}
    </div >
  )
}