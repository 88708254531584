export const ibanCountriesOptions = [
/*     {
      "label": "Albania",
      "value": "AL",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AL.svg"
    },
    {
      "label": "Andorra",
      "value": "AD",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AD.svg"
    },
    {
      "label": "Austria",
      "value": "AT",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AT.svg"
    },
    {
      "label": "Azerbaijan, Republic of",
      "value": "AZ",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AZ.svg"
    },
    {
      "label": "Bahrain",
      "value": "BH",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg"
    },
    {
      "label": "Belgium",
      "value": "BE",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BE.svg"
    },
    {
      "label": "Bosnia and Herzegovina",
      "value": "BA",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BA.svg"
    },
    {
      "label": "Brazil",
      "value": "BR",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BR.svg"
    },
    {
      "label": "Bulgaria",
      "value": "BG",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BG.svg"
    },
    {
      "label": "Costa Rica",
      "value": "CR",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CR.svg"
    },
    {
      "label": "Croatia",
      "value": "HR",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HR.svg"
    },
    {
      "label": "Cyprus",
      "value": "CY",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CY.svg"
    },
    {
      "label": "Czech Republic",
      "value": "CZ",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CZ.svg"
    }, */
    {
      "label": "Denmark",
      "value": "DK",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DK.svg"
    },
/*     {
      "label": "Dominican Republic",
      "value": "DO",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DO.svg"
    },
    {
      "label": "Estonia",
      "value": "EE",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EE.svg"
    },
    {
      "label": "Faroe Islands",
      "value": "FO",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FO.svg"
    }, */   {
      "label": "Finland",
      "value": "FI",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FI.svg"
    },
    /*  {
      "label": "France",
      "value": "FR",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg"
    },
    {
      "label": "Georgia",
      "value": "GE",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GE.svg"
    },
    {
      "label": "Germany",
      "value": "DE",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DE.svg"
    },
    {
      "label": "Gibraltar",
      "value": "GI",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GI.svg"
    },
    {
      "label": "Greece",
      "value": "GR",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GR.svg"
    },
    {
      "label": "Greenland",
      "value": "GL",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GL.svg"
    },
    {
      "label": "Guatemala",
      "value": "GT",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GT.svg"
    },
    {
      "label": "Hungary",
      "value": "HU",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HU.svg"
    },
    {
      "label": "Iceland",
      "value": "IS",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IS.svg"
    },
    {
      "label": "Ireland",
      "value": "IE",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IE.svg"
    },
    {
      "label": "Israel",
      "value": "IL",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IL.svg"
    },
    {
      "label": "Italy",
      "value": "IT",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IT.svg"
    },
    {
      "label": "Jordan",
      "value": "JO",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JO.svg"
    },
    {
      "label": "Kazakhstan",
      "value": "KZ",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KZ.svg"
    },
    {
      "label": "Kuwait",
      "value": "KW",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KW.svg"
    },
    {
      "label": "Latvia",
      "value": "LV",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LV.svg"
    },
    {
      "label": "Lebanon",
      "value": "LB",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LB.svg"
    },
    {
      "label": "Liechtenstein (Principality of)",
      "value": "LI",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LI.svg"
    },
    {
      "label": "Lithuania",
      "value": "LT",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LT.svg"
    },
    {
      "label": "Luxembourg",
      "value": "LU",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LU.svg"
    },
    {
      "label": "Macedonia",
      "value": "MK",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MK.svg"
    },
    {
      "label": "Malta",
      "value": "MT",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MT.svg"
    },
    {
      "label": "Mauritania",
      "value": "MR",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MR.svg"
    },
    {
      "label": "Mauritius",
      "value": "MU",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MU.svg"
    },
    {
      "label": "Moldova",
      "value": "MD",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MD.svg"
    },
    {
      "label": "Monaco",
      "value": "MC",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MC.svg"
    },
    {
      "label": "Montenegro",
      "value": "ME",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ME.svg"
    },
    {
      "label": "Netherlands",
      "value": "NL",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NL.svg"
    }, */
    {
      "label": "Norway",
      "value": "NO",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NO.svg"
    },
/*     {
      "label": "Pakistan",
      "value": "PK",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PK.svg"
    },
    {
      "label": "Poland",
      "value": "PL",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PL.svg"
    },
    {
      "label": "Palestinian Territory, Occupied",
      "value": "PS",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PS.svg"
    },
    {
      "label": "Portugal",
      "value": "PT",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PT.svg"
    },
    {
      "label": "Qatar",
      "value": "QA",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/QA.svg"
    },
    {
      "label": "Republic of Kosovo",
      "value": "XK",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/XK.svg"
    },
    {
      "label": "Romania",
      "value": "RO",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RO.svg"
    },
    {
      "label": "Saint Lucia",
      "value": "LC",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LC.svg"
    },
    {
      "label": "San Marino",
      "value": "SM",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SM.svg"
    },
    {
      "label": "Sao Tome And Principe",
      "value": "ST",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ST.svg"
    },
    {
      "label": "Saudi Arabia",
      "value": "SA",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SA.svg"
    },
    {
      "label": "Serbia",
      "value": "RS",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RS.svg"
    },
    {
      "label": "Seychelles",
      "value": "SC",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SC.svg"
    },
    {
      "label": "Slovak Republic",
      "value": "SK",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SK.svg"
    },
    {
      "label": "Slovenia",
      "value": "SI",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SI.svg"
    },
    {
      "label": "Spain",
      "value": "ES",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg"
    }, */
    {
      "label": "Sweden",
      "value": "SE",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SE.svg"
    },
/*     {
      "label": "Switzerland",
      "value": "CH",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CH.svg"
    },
    {
      "label": "Timor-Leste",
      "value": "TL",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TL.svg"
    },
    {
      "label": "Tunisia",
      "value": "TN",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TN.svg"
    },
    {
      "label": "Turkey",
      "value": "TR",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TR.svg"
    },
    {
      "label": "Ukraine",
      "value": "UA",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UA.svg"
    },
    {
      "label": "United Arab Emirates",
      "value": "AE",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AE.svg"
    },
    {
      "label": "United Kingdom",
      "value": "GB",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg"
    },
    {
      "label": "Virgin Islands, British",
      "value": "VG",
      "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VG.svg"
    } */
  ]