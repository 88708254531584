import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

export const Tooltip = ({
  text,
  icon,
}) => {
  const Icon = icon
  const id = uuidv4()
  return (
    <div style={{ display: 'inline-block' }}>
      <Icon data-tip data-for={id} className="h-5 w-5" />
      <ReactTooltip id={id}>
        <span>{text}</span>
      </ReactTooltip>
    </div>
  );
}