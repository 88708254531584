import { setProperty } from '../utils/property';
import humanizeString from 'humanize-string';
import { useSelector } from 'react-redux';
import _ from 'lodash';

export const usePropertyToLabel = (data, setData, actionsHandleChanges, propertyToSync = 'label') => {
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const onChange = (e, type) => {
    const changes = [e]

    const { name, value } = e.target;
    if (value && data.labelSetManually !== true) {
      const event = {
        target: {
          name: propertyToSync,
          value: humanizeString(value)
        }
      }
      setProperty(data, event.target.name, event.target.value, type)
      changes.push(event)
    }

    actionsHandleChanges(dialogDefinition, changes, data.id, type)
    setProperty(data, name, value, type)

    setData(data => ({
      ...data
    }));
  }

  return {
    onChange
  }
}
