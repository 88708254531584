import { testDataImage } from './test-data-images'
import { v4 as uuidv4 } from 'uuid';

export const testDataTables = {
    tables: {
        space: 1,
        backgroundColor: '#edf6ed',
        containers: [
            {
                rows: [
                    {
                        orientation: 0,
                        borderWidth: 0,
                        spacing: 1,
                        columns: [
                            {
                                elements: [
                                    {
                                        type: 10,
                                        property: 'Table',
                                        placeholder: 'Metaforce logo',
                                        width: 160,
                                        maxRows: 10,
                                        editable: true,
                                        trailingText: '%',
                                        options: [
                                            "Select from dropdown",
                                            "Fond 1",
                                            "Fond 2",
                                            "Fond 3",
                                            "Fond 4",
                                            "Fond 5",
                                            "Fond 6",
                                            "Fond 6",
                                        ],
                                        layout: {
                                            order: 5,
                                            right: false
                                        },
                                        styles: {
                                            borderWidth: 0
                                        },
                                        tableValues: [
                                            {
                                                id: uuidv4(),
                                                selectedValue: 'Fond 1',
                                                value: 123,
                                            },
                                            {
                                                id: uuidv4(),
                                                selectedValue: 'Fond 2',
                                                value: 5,
                                            },
                                        ]
                                    }]
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                borderWidth: '0',
                                elements: [
                                    {
                                        type: 8,
                                        borderWidth: '2px',
                                        borderColor: 'black',
                                    }
                                ],
                                contentAlign: 'center',
                                space: 1
                            }
                        ],
                        space: 10,
                        backgroundColor: null
                    },
                    {
                        orientation: 0,
                        borderWidth: 0,
                        spacing: 1,
                        columns: [
                            {
                                elements: [
                                    {
                                        type: 10,
                                        property: 'Table',
                                        placeholder: 'Metaforce logo',
                                        width: 160,
                                        maxRows: 10,
                                        readOnly: true,
                                        leadingText: 'kr',
                                        backgroundColor: '#fff',
                                        options: [
                                            "Select from dropdown",
                                            "Fond 1",
                                            "Fond 2",
                                            "Fond 3",
                                            "Fond 4",
                                            "Fond 5",
                                            "Fond 6",
                                        ],
                                        layout: {
                                            order: 5,
                                            right: false
                                        },
                                        styles: {
                                            borderWidth: 0
                                        },
                                        tableValues: [
                                            {
                                                id: uuidv4(),
                                                selectedValue: 'Fond 6',
                                                value: 123,
                                            }
                                        ]
                                    }]
                            }
                        ]
                    }
                ]
            }
        ]
    }
}
