import './styles.scss';

import { useState, useRef, useCallback, useEffect } from "react";
import useClickOutside from "../../hooks/useClickOutside";

export default function BorderPicker({ onChange, name, value }) {
    const borderWidths = value?.split(' ') || []
    const widthIndex = borderWidths.findIndex(v => v.includes('px'))

    let borderStyle = 'all'

    if (borderWidths.length > 1) {

        let borderStyles = []

        borderWidths.forEach((width, i) => {

            if (width.includes('px')) {
                if (i === 0)
                    borderStyles.push('top')
                if (i === 1)
                    borderStyles.push('right')
                if (i === 2)
                    borderStyles.push('bottom')
                if (i === 3)
                    borderStyles.push('left')
            }
        })

        if (borderStyles.length)
            borderStyle = borderStyles.join(' ')

        if (borderStyles.length === 4)
            borderStyle = 'all'
    }

    const [style, setStyle] = useState(borderStyle)
    const [width, setWidth] = useState(borderWidths[widthIndex]?.match(/\d+/)?.[0] || 0)
    const [isOpen, toggle] = useState(false);
    const popover = useRef();
    const close = useCallback(() => toggle(false), []);

    useEffect(() => setWidth(borderWidths[widthIndex]?.match(/\d+/)?.[0] || 0), [value])

    useClickOutside(popover, close);

    const handleStyleChange = (pStyle) => {  //always use e.target.value as state doesn't seem to contain the current value
        let currentStyle = style
        
        if (pStyle === style)
            currentStyle = 'remove'
        else if (pStyle === 'all')
            currentStyle = 'all'
        else {
            if (currentStyle.includes('all'))
                currentStyle = currentStyle.replace('all', '')

            if (currentStyle.includes(pStyle))
                currentStyle = currentStyle.replace(pStyle, '')
            else
                currentStyle = currentStyle.concat(` ${pStyle}`)

            currentStyle = currentStyle.trim()

            if (currentStyle.split(' ').length === 4)
                currentStyle = 'all'
        }

        setStyle(currentStyle);
        handleOnChange({}, undefined, currentStyle)
    }

    const handleWidthChange = (e) => {
        setWidth(e.target.value);
        handleOnChange(e, undefined, undefined, e.target.value)
    }

    const handleOnChange = (e, type, pStyle, pWidth)  => {
        let locBorderWidths = [];
        let currentStyle = pStyle || style
        
        if (currentStyle === 'all')
            locBorderWidths = [`${pWidth || width}px`]
        else if (currentStyle === 'remove')
            locBorderWidths = [0]
        else {
            locBorderWidths[0] = currentStyle?.includes('top') ? `${pWidth || width}px` : 0
            locBorderWidths[1] = currentStyle?.includes('right') ? `${pWidth || width}px` : 0
            locBorderWidths[2] = currentStyle?.includes('bottom') ? `${pWidth || width}px` : 0
            locBorderWidths[3] = currentStyle?.includes('left') ? `${pWidth || width}px` : 0
        }
        
        e.target = {
            name: name,
            value: locBorderWidths.join(' ')
        };

        onChange(e, type)
    }
  
    return (
        <div className="picker">
            <button className="w-10 h-10 flex justify-center items-center rounded border border-gray-200 shadow-sm hover:bg-gray-50" onClick={() => toggle(true)}>
                <div className="grid grid-cols-2">
                    <div className="w-4 h-4 border-t border-l border-gray-400"><div className="w-4 h-4 border-r border-b border-dashed border-gray-400" /></div>
                    <div className="w-4 h-4 border-t border-r border-gray-400" />
                    <div className="w-4 h-4 border-b border-l border-gray-400"><div className="w-4 h-4 border-r border-dashed border-gray-400" /></div>
                    <div className="w-4 h-4 border-b border-r border-gray-400"><div className="w-4 h-4 border-t border-dashed border-gray-400" /></div>
                </div>
            </button>

            {isOpen && (
                <div className="-left-72 z-50 popover rounded-md border border-gray-300 text-gray-500 p-3 bg-white" ref={popover}>

                    <div className="grid grid-cols-5 w-72">
                        <button className={`w-8 h-8 flex justify-center items-center rounded border border-gray-400 shadow-sm hover:bg-gray-50 ${ style.includes('left') ? 'bg-gray-200' : '' }`}>
                            <div style={{ borderLeftColor: 'gray' }} className="w-6 h-6 border border-gray-400 border-l-4 cursor-pointer" onClick={() => { handleStyleChange('left') }} />
                        </button>
                        <button className={`w-8 h-8 flex justify-center items-center rounded border border-gray-400 shadow-sm hover:bg-gray-50 ${ style.includes('right') ? 'bg-gray-200' : '' }`}>
                            <div style={{ borderRightColor: 'gray' }} className="w-6 h-6 border border-gray-400 border-r-4 cursor-pointer" onClick={() => { handleStyleChange('right') }} />
                        </button>
                        <button className={`w-8 h-8 flex justify-center items-center rounded border border-gray-400 shadow-sm hover:bg-gray-50 ${ style.includes('top') ? 'bg-gray-200' : '' }`}>
                            <div style={{ borderTopColor: 'gray' }} className="w-6 h-6 border border-gray-400 border-t-4 cursor-pointer" onClick={() => { handleStyleChange('top') }} />
                        </button>
                        <button className={`w-8 h-8 flex justify-center items-center rounded border border-gray-400 shadow-sm hover:bg-gray-50 ${ style.includes('bottom') ? 'bg-gray-200' : '' }`}>
                            <div style={{ borderBottomColor: 'gray' }} className="w-6 h-6 border border-gray-400 border-b-4 cursor-pointer" onClick={() => { handleStyleChange('bottom') }} />
                        </button>
                        <button className={`w-8 h-8 flex justify-center items-center rounded border border-gray-400 shadow-sm hover:bg-gray-50 ${ style === 'all' ? 'bg-gray-200' : '' }`}>
                            <div style={{ borderColor: 'gray' }} className="w-6 h-6 border-4 border-gray-400 cursor-pointer" onClick={() => { handleStyleChange('all') }} />
                        </button>
                    </div>
                    <div className="mt-3">
                        <label htmlFor="border-width">Size:</label>

                        <select
                        id="border-width"
                        onChange={handleWidthChange}
                        className="ml-1 focus:ring-gray-400 focus:border-gray-400 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md w-20 cursor-pointer"
                        value={width}
                        >
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        </select>
                    </div>
                    <button
                        type="button"
                        onClick={close}
                        className="inline-flex mt-3 items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                        Close
                    </button>
                </div>
                
            )}
        </div>
    );
}