import {
    testDataImage
} from './test-data-images'

export const testDataStepByStep = {
    data: {
        type: 2,
        space: 10,
        containers: [{
                static: true,
                rows: [{
                    columns: [
                        {
                            contentAlign: 'left',
                            borderWidth: '0',
                            elements: [{
                                type: 4,
                                property: 'Logo',
                                value: testDataImage.metaforceLogo,
                                placeholder: 'Metaforce logo',
                                width: 100,
                                layout: {
                                    order: 5,
                                    right: true
                                },
                                styles: {
                                    borderWidth: '0'
                                }
                            }]
                        }
                    ]
                }, ],
            },
            {
                borderWidth: 1,
                borderColor: '#e5e7eb',
                roundedCorners: true,
                space: 6,
                rows: [{
                    columns: [{
                        elements: [{
                            type: 16,
                            property: 'checkbox',
                            trailingText: 'other background on checked',
                            backgroundColor: '#ff6e70',
                            value: '',
                            layout: {
                                order: 2,
                            },
                            options: [
                              {name: 'Go to 3', description: 'Description 1 Description 1 Description 1 Description 1', value: '', nextContainer: 3},
                              {name: 'Go to 4', description: 'Description 2 Description 2 Description 2 Description 2', value: '', nextContainer: 4},
                              {name: 'Go to 5', description: 'Description 3 Description 3 Description 3 Description 3', value: '', nextContainer: 5}
                            ],
                            text: {
                                level: '1',
                                alignment: 'center',
                                fontFamily: 'arial',
                                size: 18,
                                bold: false,
                                italic: false,
                                underline: false,
                                color: '#fff'
                            }
                        }]
                    }]
                }, ]
            },
            {
                borderWidth: 1,
                borderColor: '#e5e7eb',
                roundedCorners: true,
                space: 6,
                rows: [{
                    columns: [{
                        elements: [{
                            type: 2,
                            inputType: 2,
                            property: 'test',
                            value: 'Metaforce',
                            label: 'Monthly Savings',
                            placeholder: 'Monthly savings amount 2',
                            leadingText: '$',
                            trailingText: 'USD',
                            borderWidth: '1px',
                            layout: {
                                order: 2,
                            },
                            text: {
                                alignment: 'center',
                                fontFamily: 'arial',
                                size: 18,
                                bold: false,
                                italic: false,
                                underline: false,
                                color: 'black'
                            }
                        }]
                    }]
                }, ]
            },
            {
                borderWidth: 1,
                borderColor: '#e5e7eb',
                roundedCorners: true,
                space: 6,
                rows: [{
                    columns: [{
                        elements: [{
                            type: 2,
                            inputType: 2,
                            property: 'test',
                            value: 'Metaforce',
                            label: 'Monthly Savings',
                            placeholder: 'Monthly savings amount 3',
                            leadingText: '$',
                            trailingText: 'USD',
                            borderWidth: '1px',
                            layout: {
                                order: 2,
                            },
                            text: {
                                alignment: 'center',
                                fontFamily: 'arial',
                                size: 18,
                                bold: false,
                                italic: false,
                                underline: false,
                                color: 'black'
                            }
                        }]
                    }]
                }, ]
            },
            {
                borderWidth: 1,
                borderColor: '#e5e7eb',
                roundedCorners: true,
                space: 6,
                rows: [{
                    columns: [{
                        elements: [{
                            type: 2,
                            inputType: 2,
                            property: 'test',
                            value: 'Metaforce',
                            label: 'Monthly Savings',
                            placeholder: 'Monthly savings amount 4',
                            leadingText: '$',
                            trailingText: 'USD',
                            borderWidth: '1px',
                            layout: {
                                order: 2,
                            },
                            text: {
                                alignment: 'center',
                                fontFamily: 'arial',
                                size: 18,
                                bold: false,
                                italic: false,
                                underline: false,
                                color: 'black'
                            }
                        }]
                    }]
                }, ]
            },
            {
                borderWidth: 1,
                borderColor: '#e5e7eb',
                roundedCorners: true,
                space: 6,
                rows: [{
                    columns: [{
                        elements: [{
                            type: 2,
                            inputType: 2,
                            property: 'test',
                            value: 'Metaforce',
                            label: 'Monthly Savings',
                            placeholder: 'Monthly savings amount 5',
                            leadingText: '$',
                            trailingText: 'USD',
                            borderWidth: '1px',
                            layout: {
                                order: 2,
                            },
                            text: {
                                alignment: 'center',
                                fontFamily: 'arial',
                                size: 18,
                                bold: false,
                                italic: false,
                                underline: false,
                                color: 'black'
                            }
                        }]
                    }]
                }, ]
            }
        ]
    },






    finished: {
        borderWidth: 1,
        borderColor: '#e5e7eb',
        roundedCorners: true,
        space: 6,
        rows: [{
            columns: [
                {
                    contentAlign: 'left',
                    borderWidth: '0',
                    elements: [{
                      type: 3,
                      property: 'test',
                      value: 'There are no more questions.',
                      layout: {
                          order: 2,
                      },
                      styles: {
                          borderWidth: 0
                      },
                      text: {
                          level: '1',
                          alignment: 'left',
                          fontFamily: 'sans-serif',
                          size: 34,
                          bold: false,
                          italic: false,
                          underline: false
                      }
                    },
                    {
                      type: 3,
                      property: 'test',
                      value: 'Please submit or go back and changes your responses',
                      layout: {
                          order: 2,
                      },
                      styles: {
                          borderWidth: 0
                      },
                      text: {
                          level: '1',
                          alignment: 'left',
                          fontFamily: 'sans-serif',
                          size: 20,
                          bold: false,
                          italic: false,
                          underline: false
                      }
                    }]
                }
            ]
        },
      ],
    }
}
