import React, { useState, useEffect } from 'react';
import { testDataGjensidige } from '../../utils/test-data-gjensidig'
import { testDataMetaforce } from '../../utils/test-data-metaforce'
import { testDataDina } from '../../utils/test-data-dina'
import { testDataDinaShowHide } from '../../utils/test-data-dina-show-hide'
import { testDataTables } from '../../utils/test-data-tables'
import { testDataQuestionnaire } from '../../utils/test-data-step-by-step'
import { testDataAmf } from '../../utils/test-data-amf'

import './styles.scss';
import * as Structures from "../../structures";

export default function DialogDevelopment() {
    // let testData = testDataGjensidige.gjensidige
    // let testData = testDataMetaforce.allElements
    //let testData = testDataTables.tables
    //let testData = testDataDina.dina3
    //let testData = testDataDinaShowHide.dina
    //let testData = testDataQuestionnaire.data
    let testData = testDataAmf.amf2

    return (
        <>
            <Structures.Dialog data={testData} />
        </>
    );
}
