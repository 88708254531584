import * as Property from "../../components/Properties";
export const Locked = () => {
  return (
    <>
      <Property.Group
        title='Locked'
      >
        <div className="ml-6 pt-2">
          This element is locked to a template
        </div>
      </Property.Group>
    </>
  )
}