import React, { useState, useContext } from 'react'
import './styles.scss'
import { useElementDesign } from '../useElementDesign'
import { FormContext } from '../../contexts'
import Confirmation from './confirmation'
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper'
import { useElementStyle } from '../useElementStyle'
import { DialogModes } from '../../contexts'

const defaultInputTextLineData = {
  type: 2,
  inputType: 1,
  property: 'Email',
  value: '',
  maxLength: 8,
  placeholder: 'you@example.com',
  layout: {
    order: 4,
  },
  styles: {
    borderWidth: 1
  },
  text: {
    alignment: 'left',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
    color: 'grey'
  }
}

export default function Button({ data, dialogData, ...props }) {
  const formContext = useContext(FormContext)
  const { isPreviewMode, isPreviewTemplateMode} = useContext(DialogModes)
  const [openConfirmation, setOpenConfirmation] = useState(false)

  data = data || defaultInputTextLineData;

  const roundedCornerClass = data?.roundedCorners ? 'rounded-md' : ''

  const { textStyle, borderAndWidthStyle, flexAlignmentClass } = useElementStyle(data);
  const alignmentClass = flexAlignmentClass(data?.text?.alignment)

  const additionalStyle = {
    backgroundColor: data?.backgroundColor ? data.backgroundColor : 'white',
    ...textStyle(data.text),
    ...borderAndWidthStyle()
  }

  if(additionalStyle.width === '100%') {
    additionalStyle.width = 'auto'
  }

  const handleSubmit = (evt) => {
    // evt will be set if fired from the submit input, but will be null if triggered
    // from <Confirmation /> component. Need to preventDefault if triggered from submit input
    // to prevent the default html submit behavior from happening, as well as having this weird
    // submit event firing.
    evt?.preventDefault()
    !formContext.formErrors.length && formContext.formRef.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))
  }

  const showConfirmationMessage = (e) => {
    e.preventDefault()
    setOpenConfirmation(true);
  }

  const elementDesign = useElementDesign(data.id, data);
  const message = elementDesign.translateTerm(data.placeholder, 'description');
  const additionalProps = elementDesign.hasContext()
    ? { onClick: elementDesign.onClick }
    : { onClick: message ? showConfirmationMessage : handleSubmit }

  // Do not render submit button if form is not enabled
  if (formContext?.isFormEnabled === false) {
    return null
  }

  const isFormSubmitting = formContext?.isFormSubmitting === true;
  return (
    <InlineActionbarElementWrapper designElement={elementDesign} openElementProperties>
      <div className={`flex ${alignmentClass}`}>
        {elementDesign.translateHelpText(data)}
        <input type="submit"
          className={`inline-flex items-center px-6 py-3 border border-transparent font-medium ${roundedCornerClass} shadow-sm cursor-pointer ${elementDesign.readOnlyElementClasses()} print:hidden`}
          style={additionalStyle}
          value={elementDesign.translateTerm(data.value)}
          disabled={elementDesign.isReadOnly() || isPreviewMode || isFormSubmitting || isPreviewTemplateMode }
          {...additionalProps}
        />
        <Confirmation onSubmit={handleSubmit} message={message} open={openConfirmation} setOpen={setOpenConfirmation} />
      </div>
    </InlineActionbarElementWrapper>
  )
}
