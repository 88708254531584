import _ from 'lodash';
import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { ElementContext } from '../contexts';
import { onChangeInlineEdit } from '../utils/elements';

export const useInlineEditing = (initData) => {
    const [clonedData, setClonedData] = useState(_.cloneDeep(initData))
    const elementContext = useContext(ElementContext);
    const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

    const onChange = (e) => {
        onChangeInlineEdit(e, elementContext, dialogDefinition, clonedData, setClonedData)
    }

    useEffect(() => {
        setClonedData(_.cloneDeep(initData));
    }, [initData]);

    return {
        onChangeInlineEditing: onChange, 
    }
}