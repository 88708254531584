import { useClassNames } from "../../hooks/useClassNames";
import ReactTooltip from 'react-tooltip';

export default function ActionbarButton({ icon, hide, disabled, onClick, spaceLeft, enabled, size, label }) {
  const Icon = icon ? icon : null;
  const classNames = useClassNames();

  const sizeStyle = () => {
    switch (size) {
      case 'sm':
        return 'h-7 w-7'
      default:
        return 'h-10 w-10'
    }
  }
 

  return (
    <div 
      className={classNames.classNames(
        spaceLeft ? 'ml-1' : '',
        hide ? 'hidden' : 'grid',
        `justify-items-center tooltip`,
      )}
      onClick={disabled ? null : onClick}
    >
        <Icon 
          data-tip data-for={label}
          className={classNames.classNames(
            enabled ? 'text-indigo-600' : 'text-white',
            ` ${sizeStyle()} items-center tems-center p-1 m-1 border-gray-300 shadow-sm leading-4 font-medium rounded-md text-sm`,
          )}
        />

      <span className="tooltiptext">{label}</span>
      {/* <span class='tooltip rounded shadow-lg p-1 bg-gray-100 text-red-500 -mt-8 z-50'>{label}</span> */}
        {/* <ReactTooltip id={label} effect="solid">
          <p>{label}</p>
        </ReactTooltip> */}
    </div>
  );
}