import { testDataImage } from './test-data-images'

export const testDataDina = {
      dina1: {
          type: 2,
          space: 1,
          size: 1,
          backgroundColor: '#edf6ed',
          containers: [
          {
              static: true,
                rows: [
                    {
                        orientation: 0,
                        borderWidth: 0,
                        columns: [
                            {
                                elements: [
                                    {
                                        type: 4,
                                        property: 'Logo',
                                        value: testDataImage.dina,
                                        placeholder: 'Dina Logo',
                                        width: 190,
                                        layout: {
                                            order: 5,
                                            right: false
                                        },
                                        styles: {
                                            borderWidth: 0
                                        }
                                    }]
                            },
                            {
                                elements: [
                                    ]
                            }]
                    },
                    ],
                },
            {
            rows: [
              {
                  margin: '30px 0 0 0',
                  backgroundColor: '#44a748',
                  borderWidth: 0,
                  roundedCorners: true,
                  space: 5,
                  columns: [
                      {
                          space: 3,
                          elements: [
                              {
                                type: 3,
                                property: 'test',
                                value: 'SKADA PÅ HUSHÅLLSMASKIN',
                                layout: {
                                    order: 2,
                                },
                                styles: {
                                    borderWidth: 0
                                },
                                text: {
                                    level: '1',
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 34,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#fff'
                                }
                              },
                              {
                                type: 0,
                                property: 'test',
                                value: 'Anmälan tar cirka 5 minuter att fylla i.',
                                leadingIcon: 0,
                                layout: {
                                    order: 2,
                                },
                                styles: {
                                    borderWidth: 0
                                },
                                text: {
                                    level: '1',
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 18,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#fff'
                                }
                              },
                              {
                                type: 0,
                                property: 'test',
                                value: 'Kasta inte maskinen eftersom vi kan behöva mer information om den.',
                                leadingIcon: 2,
                                layout: {
                                    order: 2,
                                },
                                styles: {
                                    borderWidth: 0
                                },
                                text: {
                                    level: '1',
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 18,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#fff'
                                }
                              },
                              {
                                type: 0,
                                property: 'test',
                                value: 'Reparatörsrapport som fylls i av reparatör och sedan skickas till oss (ladda ner reparatörsrapporten här) Du kan antingen ta ett foto av ifylld rapport och bifoga, eller klicka här för adressuppgifter.',
                                leadingIcon: 1,
                                layout: {
                                    order: 2,
                                },
                                styles: {
                                    borderWidth: 0
                                },
                                text: {
                                    level: '1',
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 18,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#fff'
                                }
                              }
                            ]
                      }
                  ]
              },
              ],
            },
              {
                roundedCorners: true,
                margin: '30px 0 0 0',
              rows: [
              {
                  backgroundColor: '#44a748',
                  borderWidth: 0,
                  space: 5,
                  columns: [
                      {
                          space: 3,
                          elements: [
                              {
                                type: 3,
                                property: 'test',
                                value: 'Om försäkringstagaren',
                                layout: {
                                    order: 2,
                                },
                                styles: {
                                    borderWidth: 0
                                },
                                text: {
                                    level: '2',
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#fff'
                                }
                              }
                            ]
                      }
                  ]
              },
              {
                  margin: '0',
                  backgroundColor: '#fff',
                  borderWidth: 0,
                  space: 5,
                  columns: [
                      {
                          space: 10,
                          elements: [
                              {
                                  type: 2,
                                  inputType: 1,
                                  property: 'test',
                                  value: 'Namn',
                                  label: 'Namn',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 2,
                                  inputType: 2,
                                  property: 'test',
                                  value: 'Personnummer',
                                  label: 'Personnummer',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 2,
                                  inputType: 1,
                                  property: 'test',
                                  value: 'E-postadress',
                                  label: 'E-postadress',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 2,
                                  inputType: 2,
                                  property: 'test',
                                  value: 'Telefonnummer',
                                  label: 'Telefonnummer',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              }
                          ]
                      }
                  ]
              },
              ],
            },
              {
                margin: '30px 0 0 0',
                roundedCorners: true,
              rows: [
              {
                  backgroundColor: '#44a748',
                  borderWidth: 0,
                  space: 5,
                  columns: [
                      {
                          space: 3,
                          elements: [
                              {
                                type: 3,
                                property: 'test',
                                value: 'Skadeplats och skadedatum',
                                layout: {
                                    order: 2,
                                },
                                styles: {
                                    borderWidth: 0
                                },
                                text: {
                                    level: '2',
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#fff'
                                }
                              }
                            ]
                      }
                  ]
              },
              {
                  margin: '0',
                  backgroundColor: '#fff',
                  borderWidth: 0,
                  space: 5,
                  columns: [
                      {
                          space: 10,
                          elements: [
                              {
                                  type: 2,
                                  inputType: 1,
                                  property: 'test',
                                  value: 'Var inträffade skadan?',
                                  label: 'Var inträffade skadan?',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 5,
                                  inputType: 2,
                                  property: 'test',
                                  value: 'Personnummer',
                                  label: 'När hände det?',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 1,
                                  inputType: 1,
                                  property: 'test',
                                  value: 'E-postadress',
                                  label: 'Vad har skadats? Ange märke, modell och inköpsdatum',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 2,
                                  inputType: 2,
                                  property: 'test',
                                  value: 'Telefonnummer',
                                  label: 'Vem äger den skadade egendomen? ',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 13,
                                  inputType: 2,
                                  property: 'test',
                                  value: 'Telefonnummer',
                                  label: 'Har reparatör kontaktats?',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  options: [
                                    'Ja',
                                    'Nei'
                                  ],
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              }
                          ]
                      }
                  ]
              },
              ],
            },
              {
                margin: '30px 0 0 0',
                roundedCorners: true,
              rows: [
              {
                  backgroundColor: '#44a748',
                  borderWidth: 0,
                  space: 5,
                  columns: [
                      {
                          space: 3,
                          elements: [
                              {
                                type: 3,
                                property: 'test',
                                value: 'Om maskinskadan',
                                layout: {
                                    order: 2,
                                },
                                styles: {
                                    borderWidth: 0
                                },
                                text: {
                                    level: '2',
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#fff'
                                }
                              }
                            ]
                      }
                  ]
              },
              {
                  margin: '0',
                  backgroundColor: '#fff',
                  borderWidth: 0,
                  space: 5,
                  columns: [
                      {
                          space: 10,
                          elements: [
                              {
                                  type: 1,
                                  inputType: 1,
                                  property: 'test',
                                  value: 'Beskriv noggrant hur skadan gick till:',
                                  label: 'Beskriv noggrant hur skadan gick till:',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 2,
                                  inputType: 2,
                                  property: 'test',
                                  value: 'Personnummer',
                                  label: 'Ange skadebelopp i kronor:',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              }
                          ]
                      }
                  ]
              },
              ],
            },
              {
                margin: '30px 0 0 0',
                roundedCorners: true,
              rows: [
              {
                  backgroundColor: '#44a748',
                  borderWidth: 0,
                  space: 5,
                  columns: [
                      {
                          space: 3,
                          elements: [
                              {
                                type: 3,
                                property: 'test',
                                value: 'Ersättningsanspråk på byggnad som skadats',
                                layout: {
                                    order: 2,
                                },
                                styles: {
                                    borderWidth: 0
                                },
                                text: {
                                    level: '2',
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#fff'
                                }
                              }
                            ]
                      }
                  ]
              },
              {
                  margin: '0',
                  backgroundColor: '#fff',
                  borderWidth: 0,
                  space: 5,
                  columns: [
                      {
                          space: 10,
                          elements: [
                              {
                                  type: 1,
                                  inputType: 1,
                                  property: 'test',
                                  value: 'Beskriv skadan noggrant:',
                                  label: 'Beskriv skadan noggrant:',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 2,
                                  inputType: 2,
                                  property: 'test',
                                  value: 'Personnummer',
                                  label: 'Beräknad reparationskostnad i kronor:',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              }
                          ]
                      }
                  ]
              },
              ],
            },
              {
                margin: '30px 0 0 0',
                roundedCorners: true,
              rows: [
              {
                  backgroundColor: '#44a748',
                  borderWidth: 0,
                  space: 5,
                  columns: [
                      {
                          space: 3,
                          elements: [
                              {
                                type: 3,
                                property: 'test',
                                value: 'Övrig information',
                                layout: {
                                    order: 2,
                                },
                                styles: {
                                    borderWidth: 0
                                },
                                text: {
                                    level: '2',
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#fff'
                                }
                              }
                            ]
                      }
                  ]
              },
              {
                  margin: '0',
                  backgroundColor: '#fff',
                  borderWidth: 0,
                  space: 5,
                  columns: [
                      {
                          space: 10,
                          elements: [
                              {
                                  type: 13,
                                  inputType: 2,
                                  property: 'has_insurance',
                                  value: 'has_insurance',
                                  label: 'Finns försäkring även i annat försäkringsbolag?',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  options: [
                                    'Ja',
                                    'Nei'
                                  ],
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 13,
                                  inputType: 2,
                                  property: 'payout',
                                  value: 'payout',
                                  label: 'Ersättning önskas till:',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  options: [
                                    'Bankkonto',
                                    'Plusgiro',
                                    'Bankgiro'
                                  ],
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 1,
                                  inputType: 1,
                                  property: 'test',
                                  value: 'E-postadress',
                                  label: 'Vad har skadats? Ange märke, modell och inköpsdatum',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 2,
                                  inputType: 2,
                                  property: 'test',
                                  value: 'Telefonnummer',
                                  label: 'Vem äger den skadade egendomen? ',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                          ]
                      }
                  ]
              },
              ],
            },
              {
                margin: '30px 0 0 0',
                roundedCorners: true,
              rows: [
              {
                  backgroundColor: '#44a748',
                  borderWidth: 0,
                  space: 5,
                  columns: [
                      {
                          space: 3,
                          elements: [
                              {
                                type: 3,
                                property: 'test',
                                value: 'Bifoga filer',
                                layout: {
                                    order: 2,
                                },
                                styles: {
                                    borderWidth: 0
                                },
                                text: {
                                    level: '2',
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#fff'
                                }
                              }
                            ]
                      }
                  ]
              },
              {
                  margin: '0',
                  backgroundColor: '#fff',
                  borderWidth: 0,
                  space: 5,
                  columns: [
                      {
                          space: 0,
                          elements: [
                              {
                                  type: 0,
                                  inputType: 1,
                                  property: 'test',
                                  value: 'Några saker att tänka på:',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'left',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: true,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 0,
                                  inputType: 1,
                                  property: 'test',
                                  value: '- Tar du bilder med din telefon, ladda inte upp dem direkt utan spara i telefonens album först.',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'left',
                                      fontFamily: 'sans-serif',
                                      size: 18,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 0,
                                  inputType: 1,
                                  property: 'test',
                                  value: '- Alla filerna måste ha unika namn.',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'left',
                                      fontFamily: 'sans-serif',
                                      size: 18,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 12,
                                  inputType: 2,
                                  property: 'test',
                                  value: 'Personnummer',
                                  label: 'Ange skadebelopp i kronor:',
                                  placeholder: '',
                                  margin: '30px 0 0 0',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              }
                          ]
                      }
                  ]
              },
              ],
            },
              {
                margin: '30px 0 0 0',
                roundedCorners: true,
              rows: [
              {
                  padding: '40px 30px',
                  backgroundColor: '#fff',
                  borderWidth: 0,
                  space: 5,
                  columns: [
                      {
                          space: 0,
                          elements: [
                              {
                                  type: 14,
                                  inputType: 1,
                                  property: 'test',
                                  value: 'Jag har läst och godkänner Dina Försäkringars hantering av mina personuppgifter.',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'left',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: true,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              }
                          ]
                      }
                  ]
              }
          ]
        }
      ]
      },

      // NEW ONE

      dina2: {
          space: 1,
          size: 1,
          backgroundColor: '#edf6ed',
          containers: [
          {
          rows: [
              {
                  orientation: 0,
                  borderWidth: 0,
                  columns: [
                      {
                          elements: [
                              {
                                  type: 4,
                                  property: 'Logo',
                                  value: testDataImage.dina,
                                  placeholder: 'Dina Logo',
                                  width: 190,
                                  layout: {
                                      order: 5,
                                      right: false
                                  },
                                  styles: {
                                      borderWidth: 0
                                  }
                              }]
                      },
                      {
                          elements: [
                              ]
                      }]
              },
              {
                  margin: '30px 0 0 0',
                  backgroundColor: '#44a748',
                  borderWidth: 0,
                  roundedCorners: true,
                  space: 5,
                  columns: [
                      {
                          space: 3,
                          elements: [
                              {
                                type: 3,
                                property: 'test',
                                value: 'BRAND',
                                layout: {
                                    order: 2,
                                },
                                styles: {
                                    borderWidth: 0
                                },
                                text: {
                                    level: '1',
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 34,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#fff'
                                }
                              },
                              {
                                type: 0,
                                property: 'test',
                                value: 'Har du drabbats av en allvarlig skada, kontakta oss så hjälper vi dig! Vi svarar dygnet runt.',
                                leadingIcon: 3,
                                layout: {
                                    order: 2,
                                },
                                styles: {
                                    borderWidth: 0
                                },
                                text: {
                                    level: '1',
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 18,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#fff'
                                }
                              }
                            ]
                      }
                  ]
              },
              {
                  margin: '30px 0 0 0',
                  backgroundColor: '#44a748',
                  borderWidth: 0,
                  roundedCorners: true,
                  space: 5,
                  columns: [
                      {
                          space: 3,
                          elements: [
                              {
                                type: 3,
                                property: 'test',
                                value: 'Om försäkringstagaren',
                                layout: {
                                    order: 2,
                                },
                                styles: {
                                    borderWidth: 0
                                },
                                text: {
                                    level: '2',
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#fff'
                                }
                              }
                            ]
                      }
                  ]
              },
              {
                  margin: '0',
                  backgroundColor: '#fff',
                  borderWidth: 0,
                  roundedCorners: true,
                  space: 5,
                  columns: [
                      {
                          space: 10,
                          elements: [
                              {
                                  type: 2,
                                  inputType: 1,
                                  property: 'test',
                                  value: 'Namn',
                                  label: 'Namn',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 2,
                                  inputType: 2,
                                  property: 'test',
                                  value: 'Personnummer',
                                  label: 'Personnummer',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 2,
                                  inputType: 1,
                                  property: 'test',
                                  value: 'E-postadress',
                                  label: 'E-postadress',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 2,
                                  inputType: 2,
                                  property: 'test',
                                  value: 'Telefonnummer',
                                  label: 'Telefonnummer',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              }
                          ]
                      }
                  ]
              },
              {
                  margin: '30px 0 0 0',
                  backgroundColor: '#44a748',
                  borderWidth: 0,
                  roundedCorners: true,
                  space: 5,
                  columns: [
                      {
                          space: 3,
                          elements: [
                              {
                                type: 3,
                                property: 'test',
                                value: 'Skadeplats och skadedatum',
                                layout: {
                                    order: 2,
                                },
                                styles: {
                                    borderWidth: 0
                                },
                                text: {
                                    level: '2',
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#fff'
                                }
                              }
                            ]
                      }
                  ]
              },
              {
                  margin: '0',
                  backgroundColor: '#fff',
                  borderWidth: 0,
                  roundedCorners: true,
                  space: 5,
                  columns: [
                      {
                          space: 10,
                          elements: [
                              {
                                  type: 2,
                                  inputType: 1,
                                  property: 'test',
                                  value: 'Var inträffade skadan?',
                                  label: 'Var inträffade skadan?',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 5,
                                  inputType: 2,
                                  property: 'test',
                                  value: 'Personnummer',
                                  label: 'När inträffade den? Ange datum och klockslag:',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 2,
                                  inputType: 1,
                                  property: 'test',
                                  value: 'Telefonnummer',
                                  label: 'Vem äger den skadade egendomen? ',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 2,
                                  inputType: 2,
                                  property: 'test',
                                  value: 'Telefonnummer',
                                  label: 'Ange skadebelopp i kronor:',
                                  leadingText: 'kr',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 13,
                                  inputType: 2,
                                  property: 'test',
                                  value: 'Telefonnummer',
                                  label: 'Har reparatör kontaktats?',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  options: [
                                    'Ja',
                                    'Nei'
                                  ],
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 2,
                                  inputType: 2,
                                  property: 'test',
                                  value: 'Telefonnummer',
                                  label: 'Om ja, ange telefonnummer till reparatören: ',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              }
                          ]
                      }
                  ]
              },
              {
                  margin: '30px 0 0 0',
                  backgroundColor: '#44a748',
                  borderWidth: 0,
                  roundedCorners: true,
                  space: 5,
                  columns: [
                      {
                          space: 3,
                          elements: [
                              {
                                type: 3,
                                property: 'test',
                                value: 'Om branden',
                                layout: {
                                    order: 2,
                                },
                                styles: {
                                    borderWidth: 0
                                },
                                text: {
                                    level: '2',
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#fff'
                                }
                              }
                            ]
                      }
                  ]
              },
              {
                  margin: '0',
                  backgroundColor: '#fff',
                  borderWidth: 0,
                  roundedCorners: true,
                  space: 5,
                  columns: [
                      {
                          space: 10,
                          elements: [
                              {
                                  type: 1,
                                  inputType: 1,
                                  property: 'test',
                                  value: 'Beskriv noggrant hur skadan gick till:',
                                  label: 'Beskriv noggrant hur skadan gick till:',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              }
                          ]
                      }
                  ]
              },
              {
                  margin: '30px 0 0 0',
                  backgroundColor: '#44a748',
                  borderWidth: 0,
                  roundedCorners: true,
                  space: 5,
                  columns: [
                      {
                          space: 3,
                          elements: [
                              {
                                type: 3,
                                property: 'test',
                                value: 'Ersättningsanspråk för skadad byggnad',
                                layout: {
                                    order: 2,
                                },
                                styles: {
                                    borderWidth: 0
                                },
                                text: {
                                    level: '2',
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#fff'
                                }
                              }
                            ]
                      }
                  ]
              },
              {
                  margin: '0',
                  backgroundColor: '#fff',
                  borderWidth: 0,
                  roundedCorners: true,
                  space: 5,
                  columns: [
                      {
                          space: 10,
                          elements: [
                              {
                                  type: 1,
                                  inputType: 1,
                                  property: 'test',
                                  value: 'Beskriv noggrant hur skadan gick till:',
                                  label: 'Beskriv skadan:',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 2,
                                  inputType: 2,
                                  property: 'test',
                                  value: 'Personnummer',
                                  label: 'Beräknad reparationskostnad i kronor:',
                                  leadingText: 'kr',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              }
                          ]
                      }
                  ]
              },
              {
                  margin: '30px 0 0 0',
                  backgroundColor: '#44a748',
                  borderWidth: 0,
                  roundedCorners: true,
                  space: 5,
                  columns: [
                      {
                          space: 3,
                          elements: [
                              {
                                type: 3,
                                property: 'test',
                                value: 'Ersättningsanspråk för egendom som förstörts i branden',
                                layout: {
                                    order: 2,
                                },
                                styles: {
                                    borderWidth: 0
                                },
                                text: {
                                    level: '2',
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#fff'
                                }
                              }
                            ]
                      }
                  ]
              },
              {
                  margin: '0',
                  backgroundColor: '#fff',
                  borderWidth: 0,
                  roundedCorners: true,
                  space: 5,
                  columns: [
                      {
                          space: 10,
                          elements: [
                              {
                                  type: 1,
                                  inputType: 1,
                                  property: 'test',
                                  value: 'Beskriv skadan noggrant:',
                                  label: 'Föremål (ange även modell, tillverkningsnr): ',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 2,
                                  inputType: 2,
                                  property: 'test',
                                  value: 'Personnummer',
                                  label: 'Inköpsställe och år:Inköpsställe och år:',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 13,
                                  inputType: 2,
                                  property: 'has_receipt',
                                  value: 'has_receipt',
                                  label: 'Har du kvar kvitto?',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  options: [
                                    'Ja',
                                    'Nei'
                                  ],
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 2,
                                  inputType: 2,
                                  property: 'test',
                                  value: 'Personnummer',
                                  label: 'Ange nypris idag för en motsvarande: ',
                                  leadingText: 'kr',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              }
                          ]
                      }
                  ]
              },
              {
                  margin: '30px 0 0 0',
                  backgroundColor: '#44a748',
                  borderWidth: 0,
                  roundedCorners: true,
                  space: 5,
                  columns: [
                      {
                          space: 3,
                          elements: [
                              {
                                type: 3,
                                property: 'test',
                                value: 'Övrig information',
                                layout: {
                                    order: 2,
                                },
                                styles: {
                                    borderWidth: 0
                                },
                                text: {
                                    level: '2',
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#fff'
                                }
                              }
                            ]
                      }
                  ]
              },
              {
                  margin: '0',
                  backgroundColor: '#fff',
                  borderWidth: 0,
                  roundedCorners: true,
                  space: 5,
                  columns: [
                      {
                          space: 10,
                          elements: [
                              {
                                  type: 13,
                                  inputType: 2,
                                  property: 'has_insurance',
                                  value: 'has_insurance',
                                  label: 'Finns försäkring även i annat försäkringsbolag?',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  options: [
                                    'Ja',
                                    'Nei'
                                  ],
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 13,
                                  inputType: 2,
                                  property: 'payout',
                                  value: 'payout',
                                  label: 'Ersättning önskas till:',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  options: [
                                    'Bankkonto',
                                    'Plusgiro',
                                    'Bankgiro'
                                  ],
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 1,
                                  inputType: 1,
                                  property: 'test',
                                  value: 'E-postadress',
                                  label: 'Nummer (inkl. clearingnr):',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 2,
                                  inputType: 2,
                                  property: 'test',
                                  value: 'Telefonnummer',
                                  label: 'Tillhör kontonumret försäkringstagaren? Om inte, ange kontoinnehavarens namn och personnummer: ',
                                  placeholder: '',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                          ]
                      }
                  ]
              },
              {
                  margin: '30px 0 0 0',
                  backgroundColor: '#44a748',
                  borderWidth: 0,
                  roundedCorners: true,
                  space: 5,
                  columns: [
                      {
                          space: 3,
                          elements: [
                              {
                                type: 3,
                                property: 'test',
                                value: 'Bifoga filer',
                                layout: {
                                    order: 2,
                                },
                                styles: {
                                    borderWidth: 0
                                },
                                text: {
                                    level: '2',
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#fff'
                                }
                              }
                            ]
                      }
                  ]
              },
              {
                  margin: '0',
                  backgroundColor: '#fff',
                  borderWidth: 0,
                  roundedCorners: true,
                  space: 5,
                  columns: [
                      {
                          space: 0,
                          elements: [
                              {
                                  type: 0,
                                  inputType: 1,
                                  property: 'test',
                                  value: 'Några saker att tänka på:',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'left',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: true,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 0,
                                  inputType: 1,
                                  property: 'test',
                                  value: '- Tar du bilder med din telefon, ladda inte upp dem direkt utan spara i telefonens album först.',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'left',
                                      fontFamily: 'sans-serif',
                                      size: 18,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 0,
                                  inputType: 1,
                                  property: 'test',
                                  value: '- Alla filerna måste ha unika namn.',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'left',
                                      fontFamily: 'sans-serif',
                                      size: 18,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              },
                              {
                                  type: 12,
                                  inputType: 2,
                                  property: 'test',
                                  value: 'Personnummer',
                                  label: 'Ange skadebelopp i kronor:',
                                  placeholder: '',
                                  margin: '30px 0 0 0',
                                  borderWidth: '1px',
                                  borderColor: '#e5e7eb',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'center',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              }
                          ]
                      }
                  ]
              },
              {
                  margin: '30px 0 0 0',
                  padding: '40px 30px',
                  backgroundColor: '#fff',
                  borderWidth: 0,
                  roundedCorners: true,
                  space: 5,
                  columns: [
                      {
                          space: 0,
                          elements: [
                              {
                                  type: 14,
                                  inputType: 1,
                                  property: 'test',
                                  value: 'Jag har läst och godkänner Dina Försäkringars hantering av mina personuppgifter.',
                                  layout: {
                                      order: 2,
                                  },
                                  text: {
                                      alignment: 'left',
                                      fontFamily: 'sans-serif',
                                      size: 20,
                                      bold: true,
                                      italic: false,
                                      underline: false,
                                      color: 'black'
                                  }
                              }
                          ]
                      }
                  ]
              }
          ]
        }
      ]
      },
      

      dina3: {
        type: 2,
        space: 1,
        size: 1,
        backgroundColor: '#edf6ed',
        containers: [
        {
            static: true,
                rows: [
                    {
                        orientation: 0,
                        borderWidth: 0,
                        columns: [
                            {
                                elements: [
                                    {
                                        type: 4,
                                        property: 'Logo',
                                        value: testDataImage.dina,
                                        placeholder: 'Dina Logo',
                                        width: 190,
                                        layout: {
                                            order: 5,
                                            right: false
                                        },
                                        styles: {
                                            borderWidth: 0
                                        }
                                    }]
                            },
                            {
                                elements: [
                                    ]
                            }]
                    },
                    ],
                },
          {
          rows: [
            {
                margin: '30px 0 0 0',
                backgroundColor: '#44a748',
                borderWidth: 0,
                roundedCorners: true,
                space: 5,
                columns: [
                    {
                        space: 3,
                        elements: [
                            {
                              type: 3,
                              property: 'test',
                              value: 'MINA DJUR',
                              layout: {
                                  order: 2,
                              },
                              styles: {
                                  borderWidth: 0
                              },
                              text: {
                                  level: '1',
                                  alignment: 'left',
                                  fontFamily: 'sans-serif',
                                  size: 34,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: '#fff'
                              }
                            },
                            {
                              type: 0,
                              property: 'test',
                              value: 'Om ditt djur är sjukt eller skadat kontaktar du i första hand veterinär eller djursjukhus. När du fått hjälp anmäler du sedan skadan till oss via någon av kategorierna nedan.',
                              layout: {
                                  order: 2,
                              },
                              styles: {
                                  borderWidth: 0
                              },
                              text: {
                                  level: '1',
                                  alignment: 'left',
                                  fontFamily: 'sans-serif',
                                  size: 18,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: '#fff'
                              }
                            },
                            {
                              type: 0,
                              property: 'test',
                              value: 'På djursjukhus kan de ibland göra en så kallad direktreglering och ta hand om din skadeanmälan på plats. Har du fått hjälp med det behöver du inte anmäla skadan här också.',
                              layout: {
                                  order: 2,
                              },
                              styles: {
                                  borderWidth: 0
                              },
                              text: {
                                  level: '1',
                                  alignment: 'left',
                                  fontFamily: 'sans-serif',
                                  size: 18,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: '#fff'
                              }
                            }
                          ]
                    }
                ]
            },
            {
                margin: '30px 0 0 0',
                columns: [{
                    elements: [{
                        type: 16,
                        property: 'checkbox',
                        trailingText: 'other background on checked',
                        backgroundColor: '#ff6e70',
                        value: '',
                        layout: {
                            order: 2,
                        },
                        options: [
                          {name: 'Veterinärvård hund och katt', description: 'Description 1 Description 1 Description 1 Description 1', value: '', nextContainer: 3},
                          {name: 'Liv hund och katt', description: 'Description 2 Description 2 Description 2 Description 2', value: '', nextContainer: 4},
                          {name: 'Veterinärvård häst', description: 'Description 3 Description 3 Description 3 Description 3', value: '', nextContainer: 5},
                          {name: 'Liv häst', description: 'Description 3 Description 3 Description 3 Description 3', value: '', nextContainer: 5}
                        ],
                        text: {
                            level: '1',
                            alignment: 'center',
                            fontFamily: 'arial',
                            size: 18,
                            bold: false,
                            italic: false,
                            underline: false,
                            color: '#fff'
                        }
                    }]
                }]
            }
            ],
          },
            {
              roundedCorners: true,
              margin: '30px 0 0 0',
            rows: [
            {
                backgroundColor: '#44a748',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 3,
                        elements: [
                            {
                              type: 3,
                              property: 'test',
                              value: 'Om försäkringstagaren',
                              layout: {
                                  order: 2,
                              },
                              styles: {
                                  borderWidth: 0
                              },
                              text: {
                                  level: '2',
                                  alignment: 'left',
                                  fontFamily: 'sans-serif',
                                  size: 20,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: '#fff'
                              }
                            }
                          ]
                    }
                ]
            },
            {
                margin: '0',
                backgroundColor: '#fff',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 10,
                        elements: [
                            {
                                type: 2,
                                inputType: 1,
                                property: 'test',
                                value: 'Namn',
                                label: 'Namn',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 2,
                                property: 'test',
                                value: 'Personnummer',
                                label: 'Personnummer',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 1,
                                property: 'test',
                                value: 'E-postadress',
                                label: 'E-postadress',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 2,
                                property: 'test',
                                value: 'Telefonnummer',
                                label: 'Telefonnummer',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            }
                        ]
                    }
                ]
            },
            ],
          },
            {
              margin: '30px 0 0 0',
              roundedCorners: true,
            rows: [
            {
                backgroundColor: '#44a748',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 3,
                        elements: [
                            {
                              type: 3,
                              property: 'test',
                              value: 'Skadeplats och skadedatum',
                              layout: {
                                  order: 2,
                              },
                              styles: {
                                  borderWidth: 0
                              },
                              text: {
                                  level: '2',
                                  alignment: 'left',
                                  fontFamily: 'sans-serif',
                                  size: 20,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: '#fff'
                              }
                            }
                          ]
                    }
                ]
            },
            {
                margin: '0',
                backgroundColor: '#fff',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 10,
                        elements: [
                            {
                                type: 2,
                                inputType: 1,
                                property: 'test',
                                value: 'Var inträffade skadan?',
                                label: 'Var inträffade skadan?',
                                placeholder: '',
                                borderWidth: '1px',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 5,
                                inputType: 2,
                                property: 'test',
                                value: 'Personnummer',
                                label: 'När hände det?',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 1,
                                inputType: 1,
                                property: 'test',
                                value: 'E-postadress',
                                label: 'Vad har skadats? Ange märke, modell och inköpsdatum',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 2,
                                property: 'test',
                                value: 'Telefonnummer',
                                label: 'Vem äger den skadade egendomen? ',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 13,
                                inputType: 2,
                                property: 'test',
                                value: 'Telefonnummer',
                                label: 'Har reparatör kontaktats?',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                options: [
                                  'Ja',
                                  'Nei'
                                ],
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            }
                        ]
                    }
                ]
            },
            ],
          },
            {
              margin: '30px 0 0 0',
              roundedCorners: true,
            rows: [
            {
                backgroundColor: '#44a748',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 3,
                        elements: [
                            {
                              type: 3,
                              property: 'test',
                              value: 'Om maskinskadan',
                              layout: {
                                  order: 2,
                              },
                              styles: {
                                  borderWidth: 0
                              },
                              text: {
                                  level: '2',
                                  alignment: 'left',
                                  fontFamily: 'sans-serif',
                                  size: 20,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: '#fff'
                              }
                            }
                          ]
                    }
                ]
            },
            {
                margin: '0',
                backgroundColor: '#fff',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 10,
                        elements: [
                            {
                                type: 1,
                                inputType: 1,
                                property: 'test',
                                value: 'Beskriv noggrant hur skadan gick till:',
                                label: 'Beskriv noggrant hur skadan gick till:',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 2,
                                property: 'test',
                                value: 'Personnummer',
                                label: 'Ange skadebelopp i kronor:',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            }
                        ]
                    }
                ]
            },
            ],
          },
            {
              margin: '30px 0 0 0',
              roundedCorners: true,
            rows: [
            {
                backgroundColor: '#44a748',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 3,
                        elements: [
                            {
                              type: 3,
                              property: 'test',
                              value: 'Ersättningsanspråk på byggnad som skadats',
                              layout: {
                                  order: 2,
                              },
                              styles: {
                                  borderWidth: 0
                              },
                              text: {
                                  level: '2',
                                  alignment: 'left',
                                  fontFamily: 'sans-serif',
                                  size: 20,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: '#fff'
                              }
                            }
                          ]
                    }
                ]
            },
            {
                margin: '0',
                backgroundColor: '#fff',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 10,
                        elements: [
                            {
                                type: 1,
                                inputType: 1,
                                property: 'test',
                                value: 'Beskriv skadan noggrant:',
                                label: 'Beskriv skadan noggrant:',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 2,
                                property: 'test',
                                value: 'Personnummer',
                                label: 'Beräknad reparationskostnad i kronor:',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            }
                        ]
                    }
                ]
            },
            ],
          },
            {
              margin: '30px 0 0 0',
              roundedCorners: true,
            rows: [
            {
                backgroundColor: '#44a748',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 3,
                        elements: [
                            {
                              type: 3,
                              property: 'test',
                              value: 'Övrig information',
                              layout: {
                                  order: 2,
                              },
                              styles: {
                                  borderWidth: 0
                              },
                              text: {
                                  level: '2',
                                  alignment: 'left',
                                  fontFamily: 'sans-serif',
                                  size: 20,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: '#fff'
                              }
                            }
                          ]
                    }
                ]
            },
            {
                margin: '0',
                backgroundColor: '#fff',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 10,
                        elements: [
                            {
                                type: 13,
                                inputType: 2,
                                property: 'has_insurance',
                                value: 'has_insurance',
                                label: 'Finns försäkring även i annat försäkringsbolag?',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                options: [
                                  'Ja',
                                  'Nei'
                                ],
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 13,
                                inputType: 2,
                                property: 'payout',
                                value: 'payout',
                                label: 'Ersättning önskas till:',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                options: [
                                  'Bankkonto',
                                  'Plusgiro',
                                  'Bankgiro'
                                ],
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 1,
                                inputType: 1,
                                property: 'test',
                                value: 'E-postadress',
                                label: 'Vad har skadats? Ange märke, modell och inköpsdatum',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 2,
                                inputType: 2,
                                property: 'test',
                                value: 'Telefonnummer',
                                label: 'Vem äger den skadade egendomen? ',
                                placeholder: '',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                        ]
                    }
                ]
            },
            ],
          },
            {
              margin: '30px 0 0 0',
              roundedCorners: true,
            rows: [
            {
                backgroundColor: '#44a748',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 3,
                        elements: [
                            {
                              type: 3,
                              property: 'test',
                              value: 'Bifoga filer',
                              layout: {
                                  order: 2,
                              },
                              styles: {
                                  borderWidth: 0
                              },
                              text: {
                                  level: '2',
                                  alignment: 'left',
                                  fontFamily: 'sans-serif',
                                  size: 20,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: '#fff'
                              }
                            }
                          ]
                    }
                ]
            },
            {
                margin: '0',
                backgroundColor: '#fff',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 0,
                        elements: [
                            {
                                type: 0,
                                inputType: 1,
                                property: 'test',
                                value: 'Några saker att tänka på:',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: true,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 0,
                                inputType: 1,
                                property: 'test',
                                value: '- Tar du bilder med din telefon, ladda inte upp dem direkt utan spara i telefonens album först.',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 18,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 0,
                                inputType: 1,
                                property: 'test',
                                value: '- Alla filerna måste ha unika namn.',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 18,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            },
                            {
                                type: 12,
                                inputType: 2,
                                property: 'test',
                                value: 'Personnummer',
                                label: 'Ange skadebelopp i kronor:',
                                placeholder: '',
                                margin: '30px 0 0 0',
                                borderWidth: '1px',
                                borderColor: '#e5e7eb',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            }
                        ]
                    }
                ]
            },
            ],
          },
            {
              margin: '30px 0 0 0',
              roundedCorners: true,
            rows: [
            {
                padding: '40px 30px',
                backgroundColor: '#fff',
                borderWidth: 0,
                space: 5,
                columns: [
                    {
                        space: 0,
                        elements: [
                            {
                                type: 14,
                                inputType: 1,
                                property: 'test',
                                value: 'Jag har läst och godkänner Dina Försäkringars hantering av mina personuppgifter.',
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'left',
                                    fontFamily: 'sans-serif',
                                    size: 20,
                                    bold: true,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            }
                        ]
                    }
                ]
            }
        ]
      }
    ]
    },
}
