import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as Property from "../../components/Properties";
import { setProperty } from '../../utils/property';
import _ from "lodash";

export const Locked = ({
  containerId,
  initData,
  actions,
}) => {
  const [data, setSetData] = useState(_.cloneDeep(initData));
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const handleOnChange = async (e, type) => {
    const { name, value } = e.target;

    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setSetData(data => ({
      ...data
    }));
  }

  if (data.dialogObjectId && data.readOnly === true) {
    return (
      <Property.Group
        title='Template'
      >
        <div className='px-4 sm:px-6 pt-3 flex items-center justify-between'>
          Template is read-only
        </div>
      </Property.Group>
    )
  }

  return (
    <Property.Group
      title='Template'
    >
      <Property.Checkbox
        onChange={handleOnChange}
        label={'Lock to template'}
        name={'lockToDialogObject'}
        value={data.lockToDialogObject}
      />
    </Property.Group>
  )
}