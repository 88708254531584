import { testDataImage } from './test-data-images'

export const testDataGjensidige = {
    gjensidige: {
        space: 1,
        backgroundColor: '#fffff9',
        containers: [
        {
          rows: [
              {
                  orientation: 0,
                  borderWidth: 0,
                  spacing: 1,
                  columns: [
                      {
                          elements: [
                              {
                                  type: 4,
                                  property: 'Logo',
                                  value: testDataImage.gjensidige,
                                  placeholder: 'Metaforce logo',
                                  width: 160,
                                  layout: {
                                      order: 5,
                                      right: false
                                  },
                                  styles: {
                                      borderWidth: 0
                                  }
                              }]
                      },
                      {
                          elements: [
                              ]
                      }]
              },
              {
                margin: '20px 0',
                columns: [
                    {
                        borderWidth: 0,
                        elements: [
                            {
                                _id: null,
                                type: 0,
                                inputType: 0,
                                layout: {
                                    order: 2,
                                    right: null
                                },
                                text: {
                                    level: '1',
                                    alignment: 'center',
                                    fontFamily: 'arial',
                                    size: 16,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#7c5e93'
                                },
                                property: 'test',
                                value: 'Er personopplysningene dine oppdatert?',
                                placeholder: null,
                                borderWidth: 0,
                                width: null,
                                label: null,
                                leadingText: null,
                                trailingText: null,
                                backgroundColor: null
                            }
                        ],
                        contentAlign: 'center',
                        backgroundColor: null,
                        space: 2
                    }
                ],
                space: 4,
                backgroundColor: '#dde3de'
            },
            {
              columns: [
                  {
                      borderWidth: 0,
                      elements: [
                          {
                              _id: null,
                              type: 0,
                              inputType: 0,
                              layout: {
                                  order: 2,
                                  right: null
                              },
                              text: {
                                  level: '1',
                                  alignment: 'center',
                                  fontFamily: 'arial',
                                  size: 38,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: 'black'
                              },
                              property: 'test',
                              value: 'Om deg',
                              placeholder: null,
                              borderWidth: 0,
                              width: null,
                              label: null,
                              leadingText: null,
                              trailingText: null,
                              backgroundColor: null
                          }
                      ],
                      contentAlign: 'left',
                      backgroundColor: null
                  }
              ],
              space: 10
          },
          {
            columns: [
                {
                    borderWidth: 0,
                    elements: [
                        {
                            _id: null,
                            type: 0,
                            inputType: 0,
                            layout: {
                                order: 2,
                                right: null
                            },
                            text: {
                                level: '1',
                                alignment: 'left',
                                fontFamily: 'arial',
                                size: 22,
                                bold: false,
                                italic: false,
                                underline: false,
                                color: 'black'
                            },
                            property: 'test',
                            value: 'Petter Dybdahl',
                            placeholder: null,
                            borderWidth: 0,
                            width: null,
                            label: null,
                            leadingText: null,
                            trailingText: null,
                            backgroundColor: null
                        }
                    ],
                    contentAlign: 'center',
                    backgroundColor: null,
                    space: 2
                }
            ],
            space: 4,
            backgroundColor: null
        },
        {
          columns: [
              {
                  borderWidth: 0,
                  elements: [
                      {
                          _id: null,
                          type: 0,
                          inputType: 0,
                          layout: {
                              order: 2,
                              right: null
                          },
                          text: {
                              level: '1',
                              alignment: 'left',
                              fontFamily: 'arial',
                              size: 18,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          },
                          property: 'test',
                          value: 'Besøksadresse',
                          placeholder: null,
                          borderWidth: 0,
                          width: null,
                          label: null,
                          leadingText: null,
                          trailingText: null,
                          backgroundColor: null
                      },
                      {
                          _id: null,
                          type: 0,
                          inputType: 0,
                          layout: {
                              order: 2,
                              right: null
                          },
                          text: {
                              level: '1',
                              alignment: 'left',
                              fontFamily: 'arial',
                              size: 18,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: '#b0aeac'
                          },
                          property: 'test',
                          value: 'Hellalia 21',
                          placeholder: null,
                          borderWidth: 0,
                          width: null,
                          label: null,
                          leadingText: null,
                          trailingText: null,
                          backgroundColor: null
                      },
                      {
                          _id: null,
                          type: 0,
                          inputType: 0,
                          layout: {
                              order: 2,
                              right: null
                          },
                          text: {
                              level: '1',
                              alignment: 'left',
                              fontFamily: 'arial',
                              size: 18,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: '#b0aeac'
                          },
                          property: 'test',
                          value: '3142 Vestskogen',
                          placeholder: null,
                          borderWidth: 0,
                          width: null,
                          label: null,
                          leadingText: null,
                          trailingText: null,
                          backgroundColor: null
                      }
                  ],
                  contentAlign: 'center',
                  backgroundColor: null,
                  space: 2
              },
              {
                  borderWidth: 0,
                  elements: [
                      {
                          _id: null,
                          type: 0,
                          inputType: 0,
                          layout: {
                              order: 2,
                              right: null
                          },
                          text: {
                              level: '1',
                              alignment: 'left',
                              fontFamily: 'arial',
                              size: 18,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: 'black'
                          },
                          property: 'test',
                          value: 'Folkeregistersadresse',
                          placeholder: null,
                          borderWidth: 0,
                          width: null,
                          label: null,
                          leadingText: null,
                          trailingText: null,
                          backgroundColor: null
                      },
                      {
                          _id: null,
                          type: 0,
                          inputType: 0,
                          layout: {
                              order: 2,
                              right: null
                          },
                          text: {
                              level: '1',
                              alignment: 'left',
                              fontFamily: 'arial',
                              size: 18,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: '#b0aeac'
                          },
                          property: 'test',
                          value: 'Hellalia 21',
                          placeholder: null,
                          borderWidth: 0,
                          width: null,
                          label: null,
                          leadingText: null,
                          trailingText: null,
                          backgroundColor: null
                      },
                      {
                          _id: null,
                          type: 0,
                          inputType: 0,
                          layout: {
                              order: 2,
                              right: null
                          },
                          text: {
                              level: '1',
                              alignment: 'left',
                              fontFamily: 'arial',
                              size: 18,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: '#b0aeac'
                          },
                          property: 'test',
                          value: '3142 Vestskogen',
                          placeholder: null,
                          borderWidth: 0,
                          width: null,
                          label: null,
                          leadingText: null,
                          trailingText: null,
                          backgroundColor: null
                      }
                  ],
                  contentAlign: 'center',
                  backgroundColor: null,
                  space: 2
              }
          ],
          space: 4,
          backgroundColor: null
      },
      {
        margin: '20px 0 10px 0',
        columns: [
            {
                borderWidth: 0,
                elements: [
                    {
                        _id: null,
                        type: 0,
                        inputType: 0,
                        layout: {
                            order: 2,
                            right: null
                        },
                        text: {
                            level: '1',
                            alignment: 'center',
                            fontFamily: 'arial',
                            size: 38,
                            bold: false,
                            italic: false,
                            underline: false,
                            color: 'black'
                        },
                        property: 'test',
                        value: 'Kontaktinformasjon',
                        placeholder: null,
                        borderWidth: 0,
                        width: null,
                        label: null,
                        leadingText: null,
                        trailingText: null,
                        backgroundColor: null
                    }
                ],
                contentAlign: 'left',
                backgroundColor: null
            }
        ],
        space: 10
    },
            {
                columns: [
                    {
                        elements: [
                            {
                                type: 2,
                                inputType: 1,
                                property: 'test',
                                value: 'Metaforce',
                                label: 'E-postadresse',
                                placeholder: 'Din e-post',
                                backgroundColor: '#f0ece6',
                                roundedCorners: false,
                                borderWidth: '0 0 3px 0',
                                borderColor: '#8d969d',
                                leadingText: '',
                                trailingText: '',
                                labelTop: true,
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'arial',
                                    size: 18,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            }
                        ]
                    },
                    {

                    }
                ]
            },
            {
              columns: [
                  {
                      borderWidth: 0,
                      elements: [
                          {
                              _id: null,
                              type: 0,
                              inputType: 0,
                              layout: {
                                  order: 2,
                                  right: null
                              },
                              text: {
                                  level: '1',
                                  alignment: 'left',
                                  fontFamily: 'arial',
                                  size: 14,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: '#b0aeac'
                              },
                              property: 'test',
                              value: 'Du er selv ansvarlig for at Gjensidige har din oppdaterte e-postadresse.',
                              placeholder: null,
                              borderWidth: 0,
                              width: null,
                              label: null,
                              leadingText: null,
                              trailingText: null,
                              backgroundColor: null
                          }
                      ],
                      contentAlign: 'center',
                      backgroundColor: null,
                  }
              ],
              space: 4,
              backgroundColor: null
          },
          {
            margin: '30px 0 0 0',
              columns: [
                  {
                      elements: [
                          {
                              type: 2,
                              inputType: 1,
                              property: 'test',
                              value: 'Metaforce',
                              label: 'Gjenta e-postadressen',
                              placeholder: 'Din e-post',
                              backgroundColor: '#f0ece6',
                              roundedCorners: false,
                              borderWidth: '0 0 3px 0',
                              borderColor: '#8d969d',
                              leadingText: '',
                              trailingText: '',
                              labelTop: true,
                              layout: {
                                  order: 2,
                              },
                              text: {
                                  alignment: 'center',
                                  fontFamily: 'arial',
                                  size: 18,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: 'black'
                              }
                          }
                      ]
                  },
                  {

                  }
              ]
          },
          {
            margin: '30px 0 0 0',
              columns: [
                  {
                      elements: [
                          {
                              type: 2,
                              inputType: 2,
                              property: 'test',
                              value: 'Metaforce',
                              label: 'Mobil',
                              placeholder: 'Mobilnummer',
                              backgroundColor: '#f0ece6',
                              roundedCorners: false,
                              borderWidth: '0 0 3px 0',
                              borderColor: '#8d969d',
                              leadingText: '',
                              trailingText: '',
                              labelTop: true,
                              layout: {
                                  order: 2,
                              },
                              text: {
                                  alignment: 'center',
                                  fontFamily: 'arial',
                                  size: 18,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: 'black'
                              }
                          }
                      ]
                  },
                  {

                  }
              ]
          },
          {
            columns: [
                {
                    borderWidth: 0,
                    elements: [
                        {
                            _id: null,
                            type: 0,
                            inputType: 0,
                            layout: {
                                order: 2,
                                right: null
                            },
                            text: {
                                level: '1',
                                alignment: 'left',
                                fontFamily: 'arial',
                                size: 14,
                                bold: false,
                                italic: false,
                                underline: false,
                                color: '#b0aeac'
                            },
                            property: 'test',
                            value: 'Du er selv ansvarlig for at Gjensidige har din oppdaterte telefonnummer.',
                            placeholder: null,
                            borderWidth: 0,
                            width: null,
                            label: null,
                            leadingText: null,
                            trailingText: null,
                            backgroundColor: null
                        }
                    ],
                    contentAlign: 'center',
                    backgroundColor: null,
                }
            ],
            space: 4,
            backgroundColor: null
        },
        {
          margin: '30px 0 0 0',
            columns: [
                {
                  contentAlign: 'left',
                    elements: [
                        {
                            backgroundColor: '#FF6E70',
                            type: 6,
                            property: 'test',
                            value: 'Lagre',
                            roundedCorners: false,
                            backgroundColor: '#fff',
                            borderWidth: '3px',
                            borderColor: '#af9cc4',
                            layout: {
                                order: 2,
                            },
                            styles: {
                                borderWidth: 1
                            },
                            text: {
                                level: '1',
                                alignment: 'center',
                                fontFamily: 'arial',
                                size: 18,
                                bold: false,
                                italic: false,
                                underline: false,
                                color: '#000'
                            }
                        }
                    ]
                }
            ]
        }
        ]
      }
      ]
    }
}
