import { useEffect } from "react";

// Updates the height of a <textarea> when the value changes.
export const useAutoSize = (
  textAreaRef,
  value,
  isAutoSize,
  rows = 1,
  flagToResize
) => {
  useEffect(() => {
    if (textAreaRef && isAutoSize) {
      
      const rowsOnPixel = rows === 1 ? 40 : rows * 30;
      textAreaRef.style.height = rowsOnPixel + "px";
      const scrollHeight =  textAreaRef.scrollHeight;

      textAreaRef.style.height = scrollHeight + "px";
    }
  }, [isAutoSize, rows, textAreaRef, value, flagToResize]);
};