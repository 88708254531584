import React, { useState, useContext } from 'react'
import './styles.scss'
import { useElementDesign } from '../useElementDesign'
import { DialogModes, FormContext } from '../../contexts'
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper'
import { useElementStyle } from '../useElementStyle'
import Confirmation from '../Button/confirmation'

const defaultInputTextLineData = {
  type: 2,
  inputType: 1,
  property: 'Email',
  value: '',
  maxLength: 8,
  placeholder: 'you@example.com',
  layout: {
    order: 4,
  },
  styles: {
    borderWidth: 1
  },
  text: {
    alignment: 'left',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
    color: 'grey'
  }
}

export default function CancelButton({ data, ...props }) {
  const [openConfirmation, setOpenConfirmation] = useState(false)
  data = data || defaultInputTextLineData;
  const formContext = useContext(FormContext)
  const { isPreviewMode, isPreviewTemplateMode } = useContext(DialogModes)
  const elementDesign = useElementDesign(data.id, data);
  const { textStyle, borderAndWidthStyle, flexAlignmentClass } = useElementStyle(data);

  const classPrefix = "btn"
  const roundedCornerClass = data?.roundedCorners ? 'rounded-md' : ''
  const backgroundColorStyle = data?.backgroundColor ? data.backgroundColor : 'white'
  const alignmentClass = flexAlignmentClass(data?.text?.alignment)

  const style = {
    ...textStyle(data?.text),
    ...borderAndWidthStyle(),
    backgroundColor: backgroundColorStyle,
  }

  if(style.width === '100%') {
    style.width = 'auto'
  }

  const onClick = (evt) => {
    evt.preventDefault();
    setOpenConfirmation(true);
  }

  const handleSubmit = (evt) => {
    evt?.preventDefault();
    if (formContext) {
      formContext.resetForm()
    }
  }

  const message = elementDesign.translateTerm(data.placeholder, 'description');
  let additionalProps = elementDesign.hasContext()
    ? { onClick: elementDesign.onClick }
    : { onClick: message ? onClick : handleSubmit }

  const isFormSubmitting = formContext?.isFormSubmitting === true;

  return (
    <InlineActionbarElementWrapper designElement={elementDesign} openElementProperties>
      <div className={`flex ${alignmentClass}`}>
        {elementDesign.translateHelpText(data)}
        <input
          type="button"
          className={`inline-flex items-center px-6 py-3 border border-transparent font-medium ${roundedCornerClass} shadow-sm cursor-pointer ${elementDesign.elementsClasses()} ${elementDesign.readOnlyElementClasses()}`}
          style={style}
          value={elementDesign.translateTerm(data.value)}
          disabled={elementDesign.isReadOnly() || isPreviewMode || isPreviewTemplateMode || isFormSubmitting}
          {...additionalProps}
        />
        <Confirmation onSubmit={handleSubmit} message={message} open={openConfirmation} setOpen={setOpenConfirmation} />
      </div>
    </InlineActionbarElementWrapper>
  )
}