import { PlusSmIcon } from "@heroicons/react/outline";

export const PlusButton = ({
    onClick,
    disabled
  }) => {
    return (
      <button
        type="button"
        onClick={onClick}
        disabled={disabled}
        className="inline-flex items-center border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-black bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-300"
      >
        <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    );
  }