import BorderPicker from '../BorderPicker'

export const BorderStyleAndWidth = ({ onChange, label, name, value }) => {
  return (
    <div className="px-4 sm:px-6 pt-3 flex items-center">
        <span className="flex-grow flex flex-col" id="availability-label">
          <span className="text-sm font-medium text-gray-500">{label}</span>
        </span>
        <BorderPicker name={name} value={value} onChange={onChange} />
      </div>
  );
}