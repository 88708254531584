import { testDataImage } from './test-data-images'
import { v4 as uuidv4 } from 'uuid';

export const testDataAmf = {
      amf1: {
          type: 1,
          space: 1,
          size: 2,
          backgroundColor: '#e5f0f5',
          containers: [
          {
            static: true,
            rows: [
            {
                gap: 4,
                orientation: 0,
                borderWidth: 0,
                columns: [
                    {
                        span: 6,
                        elements: [
                            {
                                type: 3,
                                property: 'test',
                                value: 'Fondförsäkring',
                                layout: {
                                    order: 2,
                                },
                                styles: {
                                    borderWidth: 0
                                },
                                labelStyle: {
                                    alignment: 'center',
                                    fontFamily: 'arial',
                                    size: 18,
                                    bold: true,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                },
                                text: {
                                    level: '1',
                                    alignment: 'left',
                                    fontFamily: 'arial',
                                    size: 18,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#444'
                                }
                            },
                            {
                                type: 3,
                                property: 'test',
                                value: 'Fondval och fondbyte',
                                layout: {
                                    order: 2,
                                },
                                styles: {
                                    borderWidth: 0
                                },
                                labelStyle: {
                                    alignment: 'center',
                                    fontFamily: 'arial',
                                    size: 18,
                                    bold: true,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                },
                                text: {
                                    level: '1',
                                    alignment: 'left',
                                    fontFamily: 'arial',
                                    size: 56,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#103a5b'
                                }
                            },
                            {
                                type: 3,
                                property: 'test',
                                value: 'Du kan byta de fonder som du har hos oss idag till andra fonder som AMF erbjuder. Du kan också välja ny placering för kommande inbetalningar. På amf.se/fondutbud ser du vårt fondutbud, årliga avgifter och fondfaktablad.',
                                layout: {
                                    order: 2,
                                },
                                styles: {
                                    borderWidth: 0
                                },
                                labelStyle: {
                                    alignment: 'center',
                                    fontFamily: 'arial',
                                    size: 18,
                                    bold: true,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                },
                                text: {
                                    level: '1',
                                    alignment: 'left',
                                    fontFamily: 'arial',
                                    size: 16,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#444'
                                }
                            }]
                    },
                    {
                        elements: [
                            {
                                type: 4,
                                property: 'Logo',
                                value: testDataImage.amfLogo,
                                placeholder: 'Metaforce logo',
                                width: 160,
                                layout: {
                                    order: 5,
                                    right: true
                                },
                                styles: {
                                    borderWidth: 0
                                }
                            }]
                    }]
            },
            ],
        },

        {
            margin: '50px 0 0 0',
            static: false,
                rows: [
                    {
                        orientation: 0,
                        borderWidth: 0,
                        columns: [
                            {
                                elements: [
                                    {
                                        type: 3,
                                        property: 'test',
                                        value: 'Sökande',
                                        layout: {
                                            order: 2,
                                        },
                                        styles: {
                                            borderWidth: 0
                                        },
                                        labelStyle: {
                                            alignment: 'center',
                                            fontFamily: 'arial',
                                            size: 18,
                                            bold: true,
                                            italic: false,
                                            underline: false,
                                            color: 'black'
                                        },
                                        text: {
                                            level: '1',
                                            alignment: 'left',
                                            fontFamily: 'arial',
                                            size: 24,
                                            bold: true,
                                            italic: false,
                                            underline: false,
                                            color: '#103a5b'
                                        }
                                    }]
                            }]
                    },
                    {
                        orientation: 0,
                        borderWidth: 0,
                        gap: 2,
                        columns: [
                            {
                                span: 2,
                                elements: [
                                    {
                                        type: 2,
                                        inputType: 1,
                                        property: 'test',
                                        value: 'Metaforce',
                                        label: 'För- och efternamn',
                                        placeholder: '',
                                        backgroundColor: '#fff',
                                        roundedCorners: false,
                                        borderWidth: '2px',
                                        borderColor: '#e5e5e5',
                                        leadingText: '',
                                        trailingText: '',
                                        labelTop: true,
                                        layout: {
                                            order: 2,
                                        },
                                        labelStyle: {
                                            alignment: 'center',
                                            fontFamily: 'arial',
                                            size: 18,
                                            bold: true,
                                            italic: false,
                                            underline: false,
                                            color: 'black'
                                        },
                                        text: {
                                            alignment: 'center',
                                            fontFamily: 'arial',
                                            size: 18,
                                            bold: false,
                                            italic: false,
                                            underline: false,
                                            color: 'black'
                                        }
                                    }
                                ]
                            },
                            {
                                elements: [
                                    {
                                        type: 2,
                                        inputType: 1,
                                        property: 'test',
                                        value: 'Metaforce',
                                        label: 'Personnummer',
                                        placeholder: '',
                                        backgroundColor: '#fff',
                                        roundedCorners: false,
                                        borderWidth: '2px',
                                        borderColor: '#e5e5e5',
                                        leadingText: '',
                                        trailingText: '',
                                        labelTop: true,
                                        layout: {
                                            order: 2,
                                        },
                                        labelStyle: {
                                            alignment: 'center',
                                            fontFamily: 'arial',
                                            size: 18,
                                            bold: true,
                                            italic: false,
                                            underline: false,
                                            color: 'black'
                                        },
                                        text: {
                                            alignment: 'center',
                                            fontFamily: 'arial',
                                            size: 18,
                                            bold: false,
                                            italic: false,
                                            underline: false,
                                            color: 'black'
                                        }
                                    }
                                ]
                            }]
                    },
                    {
                        margin: '10px 0 0 0',
                        orientation: 0,
                        borderWidth: 0,
                        gap: 2,
                        columns: [
                            {
                                span: 3,
                                elements: [
                                    {
                                        type: 2,
                                        inputType: 1,
                                        property: 'test',
                                        value: 'Metaforce',
                                        label: 'Adress',
                                        placeholder: '',
                                        backgroundColor: '#fff',
                                        roundedCorners: false,
                                        borderWidth: '2px',
                                        borderColor: '#e5e5e5',
                                        leadingText: '',
                                        trailingText: '',
                                        labelTop: true,
                                        layout: {
                                            order: 2,
                                        },
                                        labelStyle: {
                                            alignment: 'center',
                                            fontFamily: 'arial',
                                            size: 18,
                                            bold: true,
                                            italic: false,
                                            underline: false,
                                            color: 'black'
                                        },
                                        text: {
                                            alignment: 'center',
                                            fontFamily: 'arial',
                                            size: 18,
                                            bold: false,
                                            italic: false,
                                            underline: false,
                                            color: 'black'
                                        }
                                    }
                                ]
                            },
                            {
                                elements: [
                                    {
                                        type: 2,
                                        inputType: 1,
                                        property: 'test',
                                        value: 'Metaforce',
                                        label: 'Postnummer',
                                        placeholder: '',
                                        backgroundColor: '#fff',
                                        roundedCorners: false,
                                        borderWidth: '2px',
                                        borderColor: '#e5e5e5',
                                        leadingText: '',
                                        trailingText: '',
                                        labelTop: true,
                                        layout: {
                                            order: 2,
                                        },
                                        labelStyle: {
                                            alignment: 'center',
                                            fontFamily: 'arial',
                                            size: 18,
                                            bold: true,
                                            italic: false,
                                            underline: false,
                                            color: 'black'
                                        },
                                        text: {
                                            alignment: 'center',
                                            fontFamily: 'arial',
                                            size: 18,
                                            bold: false,
                                            italic: false,
                                            underline: false,
                                            color: 'black'
                                        }
                                    }
                                ]
                            },
                            {
                                span: 2,
                                elements: [
                                    {
                                        type: 2,
                                        inputType: 1,
                                        property: 'test',
                                        value: 'Metaforce',
                                        label: 'Postort',
                                        placeholder: '',
                                        backgroundColor: '#fff',
                                        roundedCorners: false,
                                        borderWidth: '2px',
                                        borderColor: '#e5e5e5',
                                        leadingText: '',
                                        trailingText: '',
                                        labelTop: true,
                                        layout: {
                                            order: 2,
                                        },
                                        labelStyle: {
                                            alignment: 'center',
                                            fontFamily: 'arial',
                                            size: 18,
                                            bold: true,
                                            italic: false,
                                            underline: false,
                                            color: 'black'
                                        },
                                        text: {
                                            alignment: 'center',
                                            fontFamily: 'arial',
                                            size: 18,
                                            bold: false,
                                            italic: false,
                                            underline: false,
                                            color: 'black'
                                        }
                                    }
                                ]
                            }]
                    },
                    {
                        margin: '10px 0 0 0',
                        orientation: 0,
                        borderWidth: 0,
                        gap: 2,
                        columns: [
                            {
                                elements: [
                                    {
                                        type: 2,
                                        inputType: 1,
                                        property: 'test',
                                        value: 'Metaforce',
                                        label: 'Telefonnummer',
                                        placeholder: '',
                                        backgroundColor: '#fff',
                                        roundedCorners: false,
                                        borderWidth: '2px',
                                        borderColor: '#e5e5e5',
                                        leadingText: '',
                                        trailingText: '',
                                        labelTop: true,
                                        layout: {
                                            order: 2,
                                        },
                                        labelStyle: {
                                            alignment: 'center',
                                            fontFamily: 'arial',
                                            size: 18,
                                            bold: true,
                                            italic: false,
                                            underline: false,
                                            color: 'black'
                                        },
                                        text: {
                                            alignment: 'center',
                                            fontFamily: 'arial',
                                            size: 18,
                                            bold: false,
                                            italic: false,
                                            underline: false,
                                            color: 'black'
                                        }
                                    }
                                ]
                            },
                            {
                                elements: [
                                    {
                                        type: 2,
                                        inputType: 1,
                                        property: 'test',
                                        value: 'Metaforce',
                                        label: 'Mobilnummer',
                                        placeholder: '',
                                        backgroundColor: '#fff',
                                        roundedCorners: false,
                                        borderWidth: '2px',
                                        borderColor: '#e5e5e5',
                                        leadingText: '',
                                        trailingText: '',
                                        labelTop: true,
                                        layout: {
                                            order: 2,
                                        },
                                        labelStyle: {
                                            alignment: 'center',
                                            fontFamily: 'arial',
                                            size: 18,
                                            bold: true,
                                            italic: false,
                                            underline: false,
                                            color: 'black'
                                        },
                                        text: {
                                            alignment: 'center',
                                            fontFamily: 'arial',
                                            size: 18,
                                            bold: false,
                                            italic: false,
                                            underline: false,
                                            color: 'black'
                                        }
                                    }
                                ]
                            },
                            {
                                span: 2,
                                elements: [
                                    {
                                        type: 2,
                                        inputType: 1,
                                        property: 'test',
                                        value: 'Metaforce',
                                        label: 'E-postadress',
                                        placeholder: '',
                                        backgroundColor: '#fff',
                                        roundedCorners: false,
                                        borderWidth: '2px',
                                        borderColor: '#e5e5e5',
                                        leadingText: '',
                                        trailingText: '',
                                        labelTop: true,
                                        layout: {
                                            order: 2,
                                        },
                                        labelStyle: {
                                            alignment: 'center',
                                            fontFamily: 'arial',
                                            size: 18,
                                            bold: true,
                                            italic: false,
                                            underline: false,
                                            color: 'black'
                                        },
                                        text: {
                                            alignment: 'center',
                                            fontFamily: 'arial',
                                            size: 18,
                                            bold: false,
                                            italic: false,
                                            underline: false,
                                            color: 'black'
                                        }
                                    }
                                ]
                            }]
                    },
                    {
                        margin: '10px 0 0 0',
                        orientation: 0,
                        borderWidth: 0,
                        gap: 2,
                        columns: [
                            {
                                span: 2,
                                elements: [
                                    {
                                        type: 2,
                                        inputType: 1,
                                        property: 'test',
                                        value: 'Metaforce',
                                        label: 'Mobilnummer',
                                        placeholder: '',
                                        backgroundColor: '#fff',
                                        roundedCorners: false,
                                        borderWidth: '2px',
                                        borderColor: '#e5e5e5',
                                        leadingText: '',
                                        trailingText: '',
                                        labelTop: true,
                                        layout: {
                                            order: 2,
                                        },
                                        labelStyle: {
                                            alignment: 'center',
                                            fontFamily: 'arial',
                                            size: 18,
                                            bold: true,
                                            italic: false,
                                            underline: false,
                                            color: 'black'
                                        },
                                        text: {
                                            alignment: 'center',
                                            fontFamily: 'arial',
                                            size: 18,
                                            bold: false,
                                            italic: false,
                                            underline: false,
                                            color: 'black'
                                        }
                                    }
                                ]
                            },
                            {
                                span: 3,
                                margin: '25px 0 0 20px ',
                                elements: [
                                    {
                                        type: 0,
                                        property: 'test',
                                        value: 'AMF behandlar dina personuppgifter i enlighet med vår gällande + integritetspolicy, som du hittar på www.amf.se',
                                        label: 'E-postadress',
                                        backgroundColor: '#fff',
                                        borderWidth: '0',
                                        borderColor: '#9a9a99',
                                        layout: {
                                            order: 2,
                                        },
                                        text: {
                                            alignment: 'left',
                                            fontFamily: 'arial',
                                            size: 17,
                                            bold: true,
                                            italic: false,
                                            underline: false,
                                            color: '#444'
                                        }
                                    }
                                ]
                            }]
                    },
                    ],
                },
        {
            margin: '50px 0 0 0',
            static: false,
                rows: [
                    {
                        orientation: 0,
                        borderWidth: 0,
                        columns: [
                            {
                                elements: [
                                    {
                                        type: 3,
                                        property: 'test',
                                        value: 'Välj hur du vill placera ditt nuvarande pensionskapital',
                                        layout: {
                                            order: 2,
                                        },
                                        styles: {
                                            borderWidth: 0
                                        },
                                        text: {
                                            level: '1',
                                            alignment: 'left',
                                            fontFamily: 'arial',
                                            size: 24,
                                            bold: true,
                                            italic: false,
                                            underline: false,
                                            color: '#103a5b'
                                        }
                                    }]
                            }]
                    },
                    {
                        margin: '10px 0 0 0',
                        orientation: 0,
                        borderWidth: 0,
                        columns: [
                            {
                                elements: [
                                    {
                                        type: 10,
                                        property: 'Table',
                                        placeholder: 'Metaforce logo',
                                        width: 160,
                                        maxRows: 10,
                                        readOnly: false,
                                        displayLabel: 'Köp andelar i följande fonder',
                                        valueLabel: '%-andel per fond',
                                        roundedCorners: false,
                                        borderWidth: '2px',
                                        borderColor: '#e5e5e5',
                                        backgroundColor: '#fff',
                                        labelStyle: {
                                            alignment: 'center',
                                            fontFamily: 'arial',
                                            size: 18,
                                            bold: true,
                                            italic: false,
                                            underline: false,
                                            color: '#374151'
                                        },
                                        trailingText: '%',
                                        options: [
                                            "Select from dropdown",
                                            "Fond 1",
                                            "Fond 2",
                                            "Fond 3",
                                            "Fond 4",
                                            "Fond 5",
                                            "Fond 6",
                                            "Fond 6",
                                        ],
                                        layout: {
                                            order: 5,
                                            right: false
                                        },
                                        styles: {
                                            borderWidth: 0
                                        },
                                        tableValues: [
                                            {
                                                id: uuidv4(),
                                                selectedValue: 'Select from dropdown',
                                                value: 0,
                                            },
                                            {
                                                id: uuidv4(),
                                                selectedValue: 'Select from dropdown',
                                                value: 0,
                                            },
                                            {
                                                id: uuidv4(),
                                                selectedValue: 'Select from dropdown',
                                                value: 0,
                                            },
                                            {
                                                id: uuidv4(),
                                                selectedValue: 'Select from dropdown',
                                                value: 0,
                                            },
                                        ]
                                    }]
                            }]
                    },
                    ],
                },
        {
            margin: '50px 0 0 0',
            static: false,
                rows: [
                    {
                        orientation: 0,
                        borderWidth: 0,
                        columns: [
                            {
                                elements: [
                                    {
                                        type: 3,
                                        property: 'test',
                                        value: 'Välj hur du vill placera kommande inbetalningar',
                                        layout: {
                                            order: 2,
                                        },
                                        styles: {
                                            borderWidth: 0
                                        },
                                        text: {
                                            level: '1',
                                            alignment: 'left',
                                            fontFamily: 'arial',
                                            size: 24,
                                            bold: true,
                                            italic: false,
                                            underline: false,
                                            color: '#103a5b'
                                        }
                                    }]
                            }]
                    },
                    {
                        margin: '10px 0 0 0',
                        orientation: 0,
                        borderWidth: 0,
                        columns: [
                            {
                                elements: [
                                    {
                                        type: 10,
                                        property: 'Table',
                                        placeholder: 'Metaforce logo',
                                        width: 160,
                                        maxRows: 10,
                                        readOnly: false,
                                        displayLabel: 'Fondnamn',
                                        valueLabel: '%-andel',
                                        roundedCorners: false,
                                        borderWidth: '2px',
                                        borderColor: '#e5e5e5',
                                        backgroundColor: '#fff',
                                        labelStyle: {
                                            alignment: 'center',
                                            fontFamily: 'arial',
                                            size: 18,
                                            bold: true,
                                            italic: false,
                                            underline: false,
                                            color: '#374151'
                                        },
                                        trailingText: '%',
                                        options: [
                                            "Select from dropdown",
                                            "Fond 1",
                                            "Fond 2",
                                            "Fond 3",
                                            "Fond 4",
                                            "Fond 5",
                                            "Fond 6",
                                            "Fond 6",
                                        ],
                                        layout: {
                                            order: 5,
                                            right: false
                                        },
                                        styles: {
                                            borderWidth: 0
                                        },
                                        tableValues: [
                                            {
                                                id: uuidv4(),
                                                selectedValue: 'Select from dropdown',
                                                value: 0,
                                            },
                                            {
                                                id: uuidv4(),
                                                selectedValue: 'Select from dropdown',
                                                value: 0,
                                            },
                                            {
                                                id: uuidv4(),
                                                selectedValue: 'Select from dropdown',
                                                value: 0,
                                            },
                                            {
                                                id: uuidv4(),
                                                selectedValue: 'Select from dropdown',
                                                value: 0,
                                            },
                                        ]
                                    }]
                            }]
                    },
                    ],
                },
        {
            margin: '50px 0 0 0',
            static: false,
                rows: [
                    {
                        orientation: 0,
                        borderWidth: 0,
                        columns: [
                            {
                                elements: [
                                    {
                                        type: 3,
                                        property: 'test',
                                        value: 'Riskinformation om fondsparande',
                                        layout: {
                                            order: 2,
                                        },
                                        styles: {
                                            borderWidth: 0
                                        },
                                        text: {
                                            level: '1',
                                            alignment: 'left',
                                            fontFamily: 'arial',
                                            size: 24,
                                            bold: true,
                                            italic: false,
                                            underline: false,
                                            color: '#103a5b'
                                        }
                                    }]
                            }]
                    },
                    {
                        margin: '10px 0 0 0',
                        orientation: 0,
                        borderWidth: 0,
                        columns: [
                            {
                                elements: [
                                    {
                                        type: 3,
                                        property: 'test',
                                        value: 'Historisk avkastning är ingen garanti för framtida avkastning. Fondandelar kan både öka och minska i värde. Det är inte säkert att du får tillbaka hela det insatta kapitalet. Läs mer om våra fonder här: www.amf.se/vara-fonder/',
                                        layout: {
                                            order: 2,
                                        },
                                        styles: {
                                            borderWidth: 0
                                        },
                                        text: {
                                            level: '1',
                                            alignment: 'left',
                                            fontFamily: 'arial',
                                            size: 18,
                                            bold: false,
                                            italic: false,
                                            underline: false,
                                            color: '#444'
                                        }
                                    }]
                            }]
                    },
                    {
                        margin: '30px 0 30px 0',
                        padding: '40px 30px',
                        backgroundColor: '#fff',
                        borderWidth: 0,
                        space: 5,
                        columns: [
                            {
                                space: 0,
                                elements: [
                                    {
                                        type: 14,
                                        inputType: 1,
                                        property: 'test',
                                        value: 'AMF ansvarar inte för fondbyte som inte kan genomföras p g a att blanketten är felaktigt eller ofullständigt ifylld. Byte av fonder är för närvarande kostnadsfritt.',
                                        layout: {
                                            order: 2,
                                        },
                                        text: {
                                            alignment: 'left',
                                            fontFamily: 'sans-serif',
                                            size: 20,
                                            bold: true,
                                            italic: false,
                                            underline: false,
                                            color: 'black'
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                    ],
                }
      ]
      },



      amf2: {
        type: 1,
        space: 1,
        backgroundColor: '#e5f0f5',
        containers: [
        {
          rows: [
              {
                  orientation: 0,
                  borderWidth: 0,
                  spacing: 1,
                  columns: [
                      {
                          elements: [
                              {
                                  type: 4,
                                  property: 'Logo',
                                  value: testDataImage.amfLogo,
                                  placeholder: 'Metaforce logo',
                                  width: 160,
                                  layout: {
                                      order: 5,
                                      right: false
                                  },
                                  styles: {
                                      borderWidth: 0
                                  }
                              }]
                      },
                      {
                          elements: [
                              ]
                      }]
              },
              {
                margin: '40px 0 20px 0',
                columns: [
                    {
                        borderWidth: 0,
                        elements: [
                            {
                                _id: null,
                                type: 0,
                                inputType: 0,
                                layout: {
                                    order: 2,
                                    right: null
                                },
                                text: {
                                    level: '1',
                                    alignment: 'center',
                                    fontFamily: 'arial',
                                    size: 19,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: '#333'
                                },
                                property: 'test',
                                value: 'Er personopplysningene dine oppdatert?',
                                placeholder: null,
                                borderWidth: 0,
                                width: null,
                                label: null,
                                leadingText: null,
                                trailingText: null,
                                backgroundColor: null
                            }
                        ],
                        contentAlign: 'center',
                        backgroundColor: null,
                        space: 2
                    }
                ],
                space: 4,
                backgroundColor: '#fecfc7'
            },
            {
              margin: '30px 0',
              columns: [
                  {
                      borderWidth: 0,
                      elements: [
                          {
                              _id: null,
                              type: 0,
                              inputType: 0,
                              layout: {
                                  order: 2,
                                  right: null
                              },
                              text: {
                                  level: '1',
                                  alignment: 'center',
                                  fontFamily: 'arial',
                                  size: 38,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: '#103a5b'
                              },
                              property: 'test',
                              value: 'Om deg',
                              placeholder: null,
                              borderWidth: 0,
                              width: null,
                              label: null,
                              leadingText: null,
                              trailingText: null,
                              backgroundColor: null
                          }
                      ],
                      contentAlign: 'left',
                      backgroundColor: null
                  }
              ]
          },
          {
            columns: [
                {
                    borderWidth: 0,
                    elements: [
                        {
                            _id: null,
                            type: 0,
                            inputType: 0,
                            layout: {
                                order: 2,
                                right: null
                            },
                            text: {
                                level: '1',
                                alignment: 'left',
                                fontFamily: 'arial',
                                size: 22,
                                bold: false,
                                italic: false,
                                underline: false,
                                color: '#103a5b'
                            },
                            property: 'test',
                            value: 'Petter Dybdahl',
                            placeholder: null,
                            borderWidth: 0,
                            width: null,
                            label: null,
                            leadingText: null,
                            trailingText: null,
                            backgroundColor: null
                        }
                    ],
                    contentAlign: 'center',
                    backgroundColor: null,
                    space: 2
                }
            ],
            backgroundColor: null,
            margin: '10px 0'
        },
        {
          columns: [
              {
                  borderWidth: 0,
                  elements: [
                      {
                          _id: null,
                          type: 0,
                          inputType: 0,
                          layout: {
                              order: 2,
                              right: null
                          },
                          text: {
                              level: '1',
                              alignment: 'left',
                              fontFamily: 'arial',
                              size: 18,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: '#103a5b'
                          },
                          property: 'test',
                          value: 'Besøksadresse',
                          placeholder: null,
                          borderWidth: 0,
                          width: null,
                          label: null,
                          leadingText: null,
                          trailingText: null,
                          backgroundColor: null
                      },
                      {
                          _id: null,
                          type: 0,
                          inputType: 0,
                          layout: {
                              order: 2,
                              right: null
                          },
                          text: {
                              level: '1',
                              alignment: 'left',
                              fontFamily: 'arial',
                              size: 18,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: '#333333'
                          },
                          property: 'test',
                          value: 'Hellalia 21',
                          placeholder: null,
                          borderWidth: 0,
                          width: null,
                          label: null,
                          leadingText: null,
                          trailingText: null,
                          backgroundColor: null
                      },
                      {
                          _id: null,
                          type: 0,
                          inputType: 0,
                          layout: {
                              order: 2,
                              right: null
                          },
                          text: {
                              level: '1',
                              alignment: 'left',
                              fontFamily: 'arial',
                              size: 18,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: '#333333'
                          },
                          property: 'test',
                          value: '3142 Vestskogen',
                          placeholder: null,
                          borderWidth: 0,
                          width: null,
                          label: null,
                          leadingText: null,
                          trailingText: null,
                          backgroundColor: null
                      }
                  ],
                  contentAlign: 'center',
                  backgroundColor: null,
                  space: 2,
              },
              {
                  borderWidth: 0,
                  elements: [
                      {
                          _id: null,
                          type: 0,
                          inputType: 0,
                          layout: {
                              order: 2,
                              right: null
                          },
                          text: {
                              level: '1',
                              alignment: 'left',
                              fontFamily: 'arial',
                              size: 18,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: '#103a5b'
                          },
                          property: 'test',
                          value: 'Folkeregistersadresse',
                          placeholder: null,
                          borderWidth: 0,
                          width: null,
                          label: null,
                          leadingText: null,
                          trailingText: null,
                          backgroundColor: null
                      },
                      {
                          _id: null,
                          type: 0,
                          inputType: 0,
                          layout: {
                              order: 2,
                              right: null
                          },
                          text: {
                              level: '1',
                              alignment: 'left',
                              fontFamily: 'arial',
                              size: 18,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: '#333333'
                          },
                          property: 'test',
                          value: 'Hellalia 21',
                          placeholder: null,
                          borderWidth: 0,
                          width: null,
                          label: null,
                          leadingText: null,
                          trailingText: null,
                          backgroundColor: null
                      },
                      {
                          _id: null,
                          type: 0,
                          inputType: 0,
                          layout: {
                              order: 2,
                              right: null
                          },
                          text: {
                              level: '1',
                              alignment: 'left',
                              fontFamily: 'arial',
                              size: 18,
                              bold: false,
                              italic: false,
                              underline: false,
                              color: '#333333'
                          },
                          property: 'test',
                          value: '3142 Vestskogen',
                          placeholder: null,
                          borderWidth: 0,
                          width: null,
                          label: null,
                          leadingText: null,
                          trailingText: null,
                          backgroundColor: null
                      }
                  ],
                  contentAlign: 'center',
                  backgroundColor: null,
                  space: 2
              }
          ],
          margin: '10px 0',
          backgroundColor: null
      },
      {
        margin: '20px 0 10px 0',
        columns: [
            {
                borderWidth: 0,
                elements: [
                    {
                        _id: null,
                        type: 0,
                        inputType: 0,
                        layout: {
                            order: 2,
                            right: null
                        },
                        text: {
                            level: '1',
                            alignment: 'center',
                            fontFamily: 'arial',
                            size: 38,
                            bold: false,
                            italic: false,
                            underline: false,
                            color: '#103a5b'
                        },
                        property: 'test',
                        value: 'Kontaktinformasjon',
                        placeholder: null,
                        borderWidth: 0,
                        width: null,
                        label: null,
                        leadingText: null,
                        trailingText: null,
                        backgroundColor: null
                    }
                ],
                contentAlign: 'left',
                backgroundColor: null
            }
        ],
        margin: '50px 0 30px 0'
    },
            {
                columns: [
                    {
                        elements: [
                            {
                                type: 2,
                                inputType: 1,
                                property: 'test',
                                value: 'Metaforce',
                                label: 'E-postadresse',
                                placeholder: 'Din e-post',
                                backgroundColor: '#f0ece6',
                                roundedCorners: false,
                                borderWidth: '2px',
                                borderColor: '#e5e5e5',
                                backgroundColor: '#fff',
                                labelStyle: {
                                    alignment: 'center',
                                    fontFamily: 'arial',
                                    size: 18,
                                    bold: true,
                                    italic: false,
                                    underline: false,
                                    color: '#374151'
                                },
                                leadingText: '',
                                trailingText: '',
                                labelTop: true,
                                layout: {
                                    order: 2,
                                },
                                text: {
                                    alignment: 'center',
                                    fontFamily: 'arial',
                                    size: 18,
                                    bold: false,
                                    italic: false,
                                    underline: false,
                                    color: 'black'
                                }
                            }
                        ]
                    },
                    {

                    }
                ]
            },
            {
              columns: [
                  {
                      borderWidth: 0,
                      elements: [
                          {
                              _id: null,
                              type: 0,
                              inputType: 0,
                              layout: {
                                  order: 2,
                                  right: null
                              },
                              text: {
                                  level: '1',
                                  alignment: 'left',
                                  fontFamily: 'arial',
                                  size: 14,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: '#333333'
                              },
                              property: 'test',
                              value: 'Du er selv ansvarlig for at Gjensidige har din oppdaterte e-postadresse.',
                              placeholder: null,
                              borderWidth: 0,
                              width: null,
                              label: null,
                              leadingText: null,
                              trailingText: null,
                              backgroundColor: null
                          }
                      ],
                      contentAlign: 'center',
                      backgroundColor: null,
                  }
              ],
              margin: '10px 0',
              backgroundColor: null
          },
          {
            margin: '30px 0 0 0',
              columns: [
                  {
                      elements: [
                          {
                              type: 2,
                              inputType: 1,
                              property: 'test',
                              value: 'Metaforce',
                              label: 'Gjenta e-postadressen',
                              placeholder: 'Din e-post',
                              backgroundColor: '#f0ece6',
                              borderWidth: '2px',
                              borderColor: '#e5e5e5',
                              backgroundColor: '#fff',
                              labelStyle: {
                                  alignment: 'center',
                                  fontFamily: 'arial',
                                  size: 18,
                                  bold: true,
                                  italic: false,
                                  underline: false,
                                  color: '#374151'
                              },
                              leadingText: '',
                              trailingText: '',
                              labelTop: true,
                              layout: {
                                  order: 2,
                              },
                              text: {
                                  alignment: 'center',
                                  fontFamily: 'arial',
                                  size: 18,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: 'black'
                              }
                          }
                      ]
                  },
                  {

                  }
              ]
          },
          {
            margin: '30px 0 0 0',
              columns: [
                  {
                      elements: [
                          {
                              type: 2,
                              inputType: 2,
                              property: 'test',
                              value: 'Metaforce',
                              label: 'Mobil',
                              placeholder: 'Mobilnummer',
                              borderWidth: '2px',
                              borderColor: '#e5e5e5',
                              backgroundColor: '#fff',
                              labelStyle: {
                                  alignment: 'center',
                                  fontFamily: 'arial',
                                  size: 18,
                                  bold: true,
                                  italic: false,
                                  underline: false,
                                  color: '#374151'
                              },
                              leadingText: '',
                              trailingText: '',
                              labelTop: true,
                              layout: {
                                  order: 2,
                              },
                              text: {
                                  alignment: 'center',
                                  fontFamily: 'arial',
                                  size: 18,
                                  bold: false,
                                  italic: false,
                                  underline: false,
                                  color: 'black'
                              }
                          }
                      ]
                  },
                  {

                  }
              ]
          },
          {
            columns: [
                {
                    borderWidth: 0,
                    elements: [
                        {
                            _id: null,
                            type: 0,
                            inputType: 0,
                            layout: {
                                order: 2,
                                right: null
                            },
                            text: {
                                level: '1',
                                alignment: 'left',
                                fontFamily: 'arial',
                                size: 14,
                                bold: false,
                                italic: false,
                                underline: false,
                                color: '#333333'
                            },
                            property: 'test',
                            value: 'Du er selv ansvarlig for at Gjensidige har din oppdaterte telefonnummer.',
                            placeholder: null,
                            borderWidth: 0,
                            width: null,
                            label: null,
                            leadingText: null,
                            trailingText: null,
                            backgroundColor: null
                        }
                    ],
                    contentAlign: 'center',
                    backgroundColor: null,
                }
            ],
            margin: '10px 0',
            backgroundColor: null
        },
        {
          margin: '30px 0 0 0',
            columns: [
                {
                  contentAlign: 'left',
                    elements: [
                        {
                            backgroundColor: '#FF6E70',
                            type: 6,
                            property: 'test',
                            value: 'Lagre',
                            roundedCorners: false,
                            backgroundColor: '#103a5b',
                            borderWidth: '3px',
                            borderColor: '#103a5b',
                            layout: {
                                order: 2,
                            },
                            styles: {
                                borderWidth: 1
                            },
                            text: {
                                level: '1',
                                alignment: 'center',
                                fontFamily: 'arial',
                                size: 18,
                                bold: false,
                                italic: false,
                                underline: false,
                                color: '#fff'
                            }
                        }
                    ]
                }
            ]
        }
        ]
      }
      ]
    }
}
